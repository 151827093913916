import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import React, { useEffect, useState } from 'react'
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import title_type from '../../Tables/title_type'
import title_type_onboarding from '../../Tables/title_type_onboarding'
import ApiService from 'app/ApiService'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Autocomplete from 'app/components/Autocomplete/AsyncAutocomplete'

const SectionTitles = ['È richiesto alcun titolo di studio?']

function OnboardingEducationRequired({
    offer,
    stepOnboarding,
    setStepOnboarding,
}) {
    const [stepOnboardingEducation, setStepOnboardingEducation] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [showMore, setShowMore] = useState(false)
    const [appState, setAppState] = useState(false)
    const initofferInfo = {
        offer_guid: '',
        eqf_label: '',
        eqf_value: 0,
        // title_name: [""],
        title_name: [],
        other_title: '',
        erasmus: 'false',
        publications: 'false',
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [offerInfo, setOfferInfo] = useState(initofferInfo)
    const {
        offer_guid,
        eqf_label,
        eqf_value,
        title_name,
        other_title,
        erasmus,
        publications,
        created,
        lastUpdate,
    } = offerInfo

    const getTitleList = async () => {
        try {
            const response = await ApiService.get_offerstitlebyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            if (body?.length > 0) {
                let titleInfo = body[0]
                setOfferInfo({
                    ...offerInfo,
                    id: titleInfo.id,
                    title_name: JSON.parse(titleInfo.title_name),
                    other_title: titleInfo.other_title,
                    eqf_label: titleInfo.eqf_label,
                    eqf_value: titleInfo.eqf_value,
                    erasmus: titleInfo.erasmus,
                    // publications: titleInfo[7].toString(),
                    lastUpdate: new Date(),
                })

                if (
                    title_type_onboarding.showMore.filter(
                        (x) => x.label === titleInfo.eqf_label
                    ).length > 0
                )
                    setShowMore(true)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const onEqfSelect = (event) => {
        const selectedValue = event.target?.value

        if (!selectedValue) {
            return
        }

        const trimmedValue = selectedValue.trim().toLowerCase()

        const val = title_type.find(
            (x) => x.label.toLowerCase() === trimmedValue
        )

        if (val) {
            if (val.label === offerInfo.eqf_label) {
                setOfferInfo({
                    ...offerInfo,
                    eqf_label: '',
                    eqf_value: null,
                    other_title: null,
                    title_name: [],
                    parent_title: null,
                })
            } else if (val.value > 3) {
                setOfferInfo({
                    ...offerInfo,
                    eqf_label: val.label,
                    eqf_value: val.value,
                })
            } else {
                setOfferInfo({
                    ...offerInfo,
                    eqf_label: val.label,
                    eqf_value: val.value,
                    other_title: null,
                    title_name: [],
                    parent_title: null,
                })
            }
        }
    }

    const onSelect = (value) => {
        // console.log(value)
        setOfferInfo({
            ...offerInfo,
            other_title: value,
        })
        // console.log(userInfo)
    }

    async function handleNext() {
        if (offerInfo.eqf_value > 0) {
            const res = await ApiService.create_offertitle(offerInfo)
            if (!res.ok) return //error
        } else if (offerInfo?.id) {
            const res = await ApiService.delete_offertitle(offerInfo?.id)
            if (!res.ok) return //error
        }
        setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
    }

    const validate = () => {
        const errors = {}
        // if (!eqf_label) errors.eqf_label = "Questo campo non può essere nullo"
        // if (!(eqf_value <= 3 && eqf_value >= 0) && title_name?.length <= 0) errors.title_name = "Questo campo non può essere nullo"
        return errors
    }

    async function getData() {
        await getTitleList()
        setAppState(false)
    }

    useEffect(() => {
        if (appState === true) getData()
    }, [appState])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            } else setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    useEffect(() => {
        if (Object.keys(offer)?.length > 0) {
            setOfferInfo({
                ...offerInfo,
                offer_guid: offer?.offer_guid,
            })
            setAppState(true)
        }
    }, [offer])

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div className="onboarding-grid">
                    <h2 style={{ margin: '0 auto' }}>{SectionTitles[0]}</h2>

                    {(() => {
                        switch (stepOnboardingEducation) {
                            case 0:
                                return (
                                    <>
                                        <p className="input-error text-center">
                                            {formErrors.eqf_label}
                                        </p>
                                        <FormControl
                                            component="fieldset"
                                            className="tags-check"
                                            style={{ width: '100%' }}
                                        >
                                            {/* <FormLabel component="legend">Istruzione</FormLabel> */}
                                            <RadioGroup
                                                aria-label="Istruzione"
                                                name="istruzione"
                                                value={eqf_label}
                                                onChange={onEqfSelect}
                                                onClick={(event) =>
                                                    onEqfSelect(event)
                                                }
                                            >
                                                <div className="container-Study">
                                                    <div className="first-choice-container">
                                                        <FormControlLabel
                                                            key={
                                                                title_type_onboarding
                                                                    .show[0]
                                                                    .label
                                                            }
                                                            value={
                                                                title_type_onboarding
                                                                    .show[0]
                                                                    .label
                                                            }
                                                            control={<Radio />}
                                                            label={
                                                                title_type_onboarding
                                                                    .show[0]
                                                                    .label
                                                            }
                                                            className="large-button"
                                                        />
                                                    </div>
                                                    <div className="educationOtheChoice">
                                                        <div className="scuole-and-triennale-container">
                                                            <FormControlLabel
                                                                key={
                                                                    title_type_onboarding
                                                                        .show[1]
                                                                        .label
                                                                }
                                                                value={
                                                                    title_type_onboarding
                                                                        .show[1]
                                                                        .label
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    title_type_onboarding
                                                                        .show[1]
                                                                        .label
                                                                }
                                                                className="scuole-medie-button"
                                                            />
                                                            <FormControlLabel
                                                                key={
                                                                    title_type_onboarding
                                                                        .show[2]
                                                                        .label
                                                                }
                                                                value={
                                                                    title_type_onboarding
                                                                        .show[2]
                                                                        .label
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    title_type_onboarding
                                                                        .show[2]
                                                                        .label
                                                                }
                                                                className="laurea-triennale-button"
                                                            />
                                                        </div>

                                                        <div className="other-choices-container">
                                                            <FormControlLabel
                                                                key={
                                                                    title_type_onboarding
                                                                        .show[3]
                                                                        .label
                                                                }
                                                                value={
                                                                    title_type_onboarding
                                                                        .show[3]
                                                                        .label
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    title_type_onboarding
                                                                        .show[3]
                                                                        .label
                                                                }
                                                                className="custom-radio-label"
                                                            />
                                                            <div>
                                                                <button
                                                                    onClick={() => {
                                                                        setShowMore(
                                                                            !showMore
                                                                        )
                                                                    }}
                                                                    className={
                                                                        showMore
                                                                            ? 'underline-text'
                                                                            : 'underline-text'
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        border: 'none',
                                                                        boxShadow:
                                                                            'none',
                                                                        outline:
                                                                            'none',
                                                                        color: '#4b4c55',
                                                                        fontweight:
                                                                            '600',
                                                                        gap: '0',
                                                                    }}
                                                                >
                                                                    Altro{' '}
                                                                    {showMore ? (
                                                                        <ArrowDropUpIcon />
                                                                    ) : (
                                                                        <ArrowDropDownIcon />
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <button onClick={() => { setShowMore(!showMore) }}>Altro  {showMore ? "\u2BC5" : "\u2BC6"}  </button> */}
                                                <div className="show-more-grid">
                                                    {showMore &&
                                                        title_type_onboarding.showMore.map(
                                                            (title) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        key={
                                                                            title.label
                                                                        }
                                                                        value={
                                                                            title.label
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            title.label
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                </div>
                                            </RadioGroup>
                                        </FormControl>

                                        {eqf_value >= 3 && (
                                            <div
                                                className="input-container "
                                                style={{ gap: '0' }}
                                            >
                                                <h2
                                                    className="noMrgMob"
                                                    style={{
                                                        marginBottom: '0',
                                                    }}
                                                >
                                                    Cosa hai studiato?
                                                </h2>
                                                <div>
                                                    <p className="input-error text-center onboarding">
                                                        {formErrors.other_title}
                                                    </p>
                                                    <Autocomplete
                                                        parentValue={
                                                            other_title
                                                        }
                                                        allowNewOption={false}
                                                        onChange={onSelect}
                                                        label={
                                                            'Percorso di studi'
                                                        }
                                                        apiRequest={(
                                                            newValue
                                                        ) =>
                                                            ApiService.get_titlelist(
                                                                newValue,
                                                                eqf_value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )
                        }
                    })()}
                </div>

                <OnboardingButton
                    stepOnboarding={stepOnboarding}
                    setStepOnboarding={setStepOnboarding}
                    setSubmit={setSubmit}
                    submit={isSubmit}
                />
            </form>
        </>
    )
}

export default OnboardingEducationRequired
