import React, { useEffect, useRef, useState, useCallback } from 'react'
import ApiService from 'app/ApiService'
// import { async } from '@firebase/util';
import useAuth from 'app/hooks/useAuth'
import { Link } from 'react-router-dom'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from 'app/components/Icons/EditIcon'
import BinIcon from 'app/components/Icons/BinIcon'
import years_ranges from '../../../Tables/years_ranges'
import history from 'history.js'
import Skeleton from '@material-ui/lab/Skeleton'
import { format } from 'date-fns'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import { CompleteFormOffer } from '../../CompleteForm/CompleteFormOffer'
import { green } from '@material-ui/core/colors'

const useStyle = makeStyles({
    root: {
        '& .MuiSwitch-edgeEnd': {
            background: '#EA6C3A',
            color: '#EA6C3A',
        },
        '& 	.MuiSwitch-switchBase': {
            color: 'gray',
        },
        '& .Mui-checked': {
            // background: 'violet',
            color: '#EA6C3A',
        },
        '& .MuiSwitch-track': {
            backgroundColor: 'grey',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#EA6C3A',
        },
    },
    customSwitch: {
        '& .MuiSwitch-thumb': {
            backgroundColor: '#fff',
        },
        '& .MuiSwitch-track': {
            opacity: '1 !important',
            height: '1rem',
            backgroundColor: 'grey !important',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#EA6C3A !important',
        },
    },
})

function PreviewCardJob({
    offer,
    handleDeleteJob,
    getOfferList,
    showSwitch = true,
}) {
    const descriptionElementRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const [switchDialog, setSwitchDialog] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openPreview, setOpenPreview] = useState(false)
    //per offer
    const [hsList, setHsList] = useState([])
    const [ssList, setSsList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [expList, setExpList] = useState([])
    const [langList, setLangList] = useState([])
    const [offerInfo, setOfferInfo] = useState(offer)
    const [showMoreInfo, setShowMoreInfo] = useState(false)
    const [loading, setLoading] = useState()
    const [appState, setAppState] = useState(false)
    const [loadingExpanded, setLoadingExpanded] = useState(false)

    const {
        recruiter_guid,
        offer_guid,
        company_name,
        job_name,
        sector,
        location,
        geolocation,
        driving_license,
        job_type,
        job_contract,
        protected_category,
        isced,
        ral,
        ral_type,
        photo_url,
        description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        start_job_date,
        active,
        created,
        lastUpdate,
        isAvailable,
    } = offerInfo

    const { user } = useAuth()
    const classes = useStyle()

    const getOfferInfo = async () => {
        try {
            const response = await ApiService.get_offerbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            setOfferInfo({
                ...offerInfo,
                offer_guid: body.offer_guid,
                company_name: body.company_name,
                job_name: body.job_name,
                sector: body.sector,
                location: body.location,
                geolocation: body.geolocation,
                driving_license: body.driving_license,
                job_contract: body.job_contract,
                job_type: body.job_type,
                protected_category:
                    body?.protected_category.toString() || 'false',
                isced: body.isced,
                ral: body.ral,
                ral_type: body?.ral_type || false,
                // photo_url: body.photo_url,
                description: body.description,
                hs_weight: body.hs_weight,
                ss_weight: body.ss_weight,
                title_weight: body.title_weight,
                exp_weight: body.exp_weight,
                active: body?.active || false,
                created: new Date(body.created),
                lastUpdate: new Date(body.lastUpdate),
            })
        } catch (err) {
            console.error(err.message)
        }
    }
    const computeYearsRequired = (years) => {
        const monthDifference = years * 12
        let y_req = 0

        switch (true) {
            case monthDifference < 12:
                y_req = years_ranges.filter((x) => x.months < 12)[0].label
                break
            case monthDifference >= 12 && monthDifference < 24:
                y_req = years_ranges.filter(
                    (x) => x.months >= 12 && x.months < 24
                )[0].label

                break
            case monthDifference >= 24 && monthDifference < 60:
                y_req = years_ranges.filter(
                    (x) => x.months >= 24 && x.months < 60
                )[0].label

                break
            case monthDifference >= 60 && monthDifference < 120:
                y_req = years_ranges.filter(
                    (x) => x.months >= 12 && x.months < 24
                )[0].label

                break
            case monthDifference >= 120:
                y_req = years_ranges.filter((x) => x.months >= 120)[0].label
                break
        }

        return y_req
    }

    const getHsList = async () => {
        try {
            const response = await ApiService.get_offershsbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            setHsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getSsList = async () => {
        try {
            const response = await ApiService.get_offersssbyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setSsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getTitleList = async () => {
        try {
            const response = await ApiService.get_offerstitlebyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setTitleList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getExpList = async () => {
        try {
            const response = await ApiService.get_offersexpbyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setExpList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLangList = async () => {
        const response = await ApiService.get_offersLangbyguid(offer_guid)
        const body = await response.json()
        // console.log(body)

        try {
            setLangList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    async function getData() {
        await getExpList()
        await getTitleList()
        await getSsList()
        await getHsList()
        await getLangList()
        setAppState(false)
    }

    const handleClickOpenConfirm = (id) => {
        setOpenConfirm(true)
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    const openSwitchDialog = (event) => {
        setOfferInfo({ ...offerInfo, active: !active, lastUpdate: new Date() })
        setSwitchDialog(true)
    }
    const handleCloseSwitchDialog = () => {
        setSwitchDialog(false)
    }

    const handleActivateOffer = async () => {
        const res = await ApiService.update_activeOffer(offerInfo)
        if (res.ok) await getOfferList()
        handleCloseSwitchDialog()
    }

    useEffect(() => {
        if (appState) getData()
    }, [appState])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (user?.photoURL)
            setOfferInfo({ ...offerInfo, photo_url: user?.photoURL })
    }, [user])

    useEffect(() => {
        setOfferInfo(offer)
    }, [offer])

    return (
        <>
            {openPreview && (
                <CompleteFormOffer
                    open={openPreview}
                    setOpen={setOpenPreview}
                    guid={offer_guid}
                    // exp_list={expList}
                    // hs_list={hsList}
                    // ss_list={ssList}
                    // lang_list={languageList}
                    // title_list={titleList}
                    // cert_list={certList}
                />
            )}

            <article
                className={
                    active
                        ? 'preview-card required-card active'
                        : 'preview-card required-card'
                }
            >
                <div className="title-card m2">
                    <div className="d-flex space-between align-center">
                        {/* SWITCH */}
                        <div
                            className="buttonPreveiw"
                            style={{ alignItems: 'center' }}
                        >
                            {showSwitch && (
                                <Switch
                                    color="primary"
                                    size="medium"
                                    // disabled={active}
                                    // onChange={getValue}
                                    checked={active}
                                    onChange={openSwitchDialog}
                                    className={classes.customSwitch}
                                />
                            )}
                            <div
                                className="btn-profile blue"
                                onClick={() => setOpenPreview(true)}
                            >
                                <RemoveRedEyeIcon />
                                Visualizza anteprima
                            </div>
                            <p
                                style={{
                                    color: isAvailable ? '#33ca7f' : '#B8B8B8',
                                    fontWeight: '600',
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                {' '}
                                Stato: {isAvailable ? 'Attiva' : 'Disattiva'}
                            </p>
                        </div>

                        <div className="d-flex">
                            <button
                                // to={`/offertaTab/${offer_guid}`}
                                onClick={() =>
                                    history.push(`/offertaTab/${offer_guid}`)
                                }
                                className="icon-btn"
                            >
                                <EditIcon />
                            </button>
                            <button
                                className="icon-btn"
                                onClick={() => handleClickOpenConfirm()}
                            >
                                <BinIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <h3 className="jobheadername">{job_name}</h3>
                <div className="Jobinfogrid">
                    <div className="profileRecoffer">
                        <h4>📌 Sede di lavoro</h4>
                        {loading ? (
                            <Skeleton variant="text" width={200} />
                        ) : (
                            <p className="tags" id="graytags">
                                {location}
                            </p>
                        )}
                        <div className="profileRecoffer">
                            <h4>🖋️ Tipo di contratto</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {job_contract
                                        ? job_contract
                                        : 'Non specificato'}{' '}
                                </p>
                            )}
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="profileRecoffer">
                                <h4>💰 Range di salario</h4>
                                {loading ? (
                                    <Skeleton variant="text" width={200} />
                                ) : (
                                    <p className="tags" id="graytags">
                                        {' '}
                                        {ral_type
                                            ? 'Mensile (netto)'
                                            : 'Annuo (lordo)'}{' '}
                                        {ral ? ral : '####'} €
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="profileRecoffer">
                            <h4>📅 Data Inizio</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {start_job_date
                                        ? format(
                                              new Date(start_job_date),
                                              'dd/MM/yyyy'
                                          )
                                        : 'Non specificata'}{' '}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="centerbutton">
                    {showMoreInfo ? 'chiudi' : 'espandi'}
                    <button
                        onClick={() => {
                            setShowMoreInfo(!showMoreInfo)
                            if (!showMoreInfo) {
                                setLoadingExpanded(true)
                                getData().finally(() =>
                                    setLoadingExpanded(false)
                                )
                            }
                        }}
                        className="moreinfoButton"
                    >
                        {showMoreInfo ? (
                            <ArrowUpwardIcon />
                        ) : (
                            <ArrowDownwardIcon />
                        )}
                    </button>
                </div>
                {showMoreInfo && (
                    <div className="moreinfojob">
                        <div>
                            <h4>Esperienza Pregressa</h4>
                            {loadingExpanded ? (
                                <div className="flextags">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                </div>
                            ) : (
                                <div className="flextags">
                                    {expList.map((exp, index) => (
                                        <React.Fragment key={index}>
                                            <p className="tags" id="graytags">
                                                {exp.job_title}
                                            </p>
                                            <p className="tags" id="graytags">
                                                {computeYearsRequired(
                                                    exp.years_required
                                                )}
                                            </p>
                                            {exp.sector &&
                                                exp.sector?.map((x) => {
                                                    return (
                                                        <p
                                                            className="tags"
                                                            id="graytags"
                                                        >
                                                            {x}
                                                        </p>
                                                    )
                                                })}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div>
                            <h4>Livello di Istruzione</h4>
                            {loadingExpanded ? (
                                <div className="flextags">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                </div>
                            ) : (
                                <div className="flextags">
                                    {titleList.map((title, index) => (
                                        <React.Fragment key={index}>
                                            <p
                                                className="tags rec"
                                                id="graytags"
                                            >
                                                {' '}
                                                {title.eqf_label}
                                            </p>
                                            {title.other_title && (
                                                <p
                                                    className="tags"
                                                    id="graytags"
                                                >
                                                    {' '}
                                                    {title.other_title}
                                                </p>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div>
                            <h4>Competenze Trasversali</h4>
                            {loadingExpanded ? (
                                <div className="flextags">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                </div>
                            ) : (
                                <div className="flextags">
                                    {ssList.map((skill, index) => (
                                        <p
                                            key={index}
                                            className="tags rec"
                                            id="graytags"
                                        >
                                            {
                                                JSON.parse(skill.skill_name)
                                                    ?.soft_lv2
                                            }
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div>
                            <h4>Competenze Tecniche</h4>
                            {loadingExpanded ? (
                                <div className="flextags">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                </div>
                            ) : (
                                <div className="flextags">
                                    {hsList.map((skill, index) => (
                                        <p
                                            key={index}
                                            className="tags rec"
                                            id="graytags"
                                        >
                                            {skill.skill_name}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div>
                            <h4>Lingue</h4>
                            {loadingExpanded ? (
                                <div className="flextags">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                </div>
                            ) : (
                                <div className="flextags">
                                    {langList.map((skill, index) => (
                                        <p
                                            key={index}
                                            className="tags rec"
                                            id="graytags"
                                        >
                                            {skill.skill_name}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div>
                            <h4>Descrizione Ruolo</h4>
                            {loadingExpanded ? (
                                <div className="flextags">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={200} />
                                </div>
                            ) : (
                                <div className="flextags description">
                                    <p className="tags rec desc" id="graytags">
                                        {description}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {/* <p>
                    <span>
                        {protected_category && protected_category === 'True'
                            ? 'Categoria protetta'
                            : ''}
                    </span>
                </p> */}
                {/* <p>
                    {/* <span>Sede di lavoro</span>{' '} */}
                {/* <span className="tags" id="graytags">
                        {' '}
                        {location}
                    </span> 
                </p> */}
                {/* <p>
                    {/* <span>RAL</span> 
                    <span className="tags" id="graytags">
                        {' '}
                        {ral_type ? 'Mensile (netto)' : 'Annuo (lordo)'}{' '}
                        {ral ? ral : '####'} €
                    </span>
                </p> */}
            </article>

            {/* DIALOG PER CAMBIARE L'OFFERTA ATTIVA */}
            <Dialog
                open={switchDialog}
                onClose={handleCloseSwitchDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Vuoi cambiare offerta?'}</DialogTitle>
                {/* <DialogContent >
                    <DialogContentText
                    >
                        Confermando attiverai quest'offerta e vedrai solo i candidati relativi ad essa
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => { handleCloseChangeOffer(e); setOfferInfo({ ...offerInfo, active: !active }) }} color="primary" autoFocus>
                        Annulla
                    </Button>
                    <Button
                        onClick={handleChangeActiveOffer}
                        color="primary">
                        Attiva l'offerta
                    </Button>
                </DialogActions> */}

                {active && (
                    <>
                        <DialogContent>
                            <DialogContentText>
                                Confermando attiverai quest'offerta e vedrai
                                solo i candidati relativi ad essa
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={(e) => {
                                    handleCloseSwitchDialog(e)
                                    setOfferInfo({
                                        ...offerInfo,
                                        active: !active,
                                    })
                                }}
                                color="secondary"
                                autoFocus
                            >
                                Annulla
                            </Button>
                            <Button onClick={handleActivateOffer}>
                                Attiva l'offerta
                            </Button>
                        </DialogActions>
                    </>
                )}

                {!active && (
                    <>
                        <DialogContent>
                            <DialogContentText>
                                Confermando renderai quest'offerta inattiva e
                                non vedrai i candidati relativi ad essa
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={(e) => {
                                    handleCloseSwitchDialog(e)
                                    setOfferInfo({
                                        ...offerInfo,
                                        active: !active,
                                    })
                                }}
                                color="secondary"
                                autoFocus
                            >
                                Annulla
                            </Button>
                            <Button
                                onClick={handleActivateOffer}
                                color="primary"
                            >
                                Disattiva l'offerta
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            {/* DIALOG PER ELIMINARE L'OFFERTA */}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Sicuro?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler eliminare quest'offerta lavorativa dal
                        tuo profilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleDeleteJob(offer_guid)
                            handleCloseConfirm()
                        }}
                        color="primary"
                    >
                        Elimina offerta
                    </Button>
                    <Button
                        onClick={handleCloseConfirm}
                        color="secondary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
            {/* </div> */}
        </>
    )
}

export default PreviewCardJob
