import React, { useEffect, useRef, useState } from 'react'
import {
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ApiService from 'app/ApiService'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import EditFieldDialog from '../FooterProfile/EditField'
import LanguagesTable from 'app/components/Tables/languages_table_onboarding'

function JobCardLanguages({ langList, offerguid, getLanguageList, appState }) {
    const [open, setOpen] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [languageList, setLanguageList] = useState([])
    const [languages, setLanguages] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const initOfferInfo = {
        offer_guid: offerguid || '',
        skill_name: '',
        created: '',
        lastUpdate: '',
    }
    const [offerInfo, setOfferInfo] = useState(initOfferInfo)

    const { offer_guid, skill_name, created, lastUpdate } = offerInfo

    const defaultLanguages = [
        'Italiano',
        'Tedesco',
        'Inglese',
        'Hindi',
        'Cinese',
        'Francese',
        'Spagnolo',
        'Portoghese',
        'Arabo',
        'Olandese',
    ]

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setFormErrors({})
        getLanguageList()
        setUnsavedChanges(false)
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }

    const handleChange = (e) => {
        setUnsavedChanges(true)

        setLanguages({
            ...languages,
            [e.target.name]: e.target.checked,
        })

        // console.log(languages);

        const updatedArray = languageList.map((skill) => {
            if (skill.skill_name === e.target.name) {
                return {
                    ...skill,
                    checked: e.target.checked,
                    lastUpdate: new Date(),
                }
            }
            return skill
        })

        if (languageList.some((item) => item.skill_name === e.target.name)) {
            setLanguageList(updatedArray)
        } else {
            setLanguageList([
                ...updatedArray,
                {
                    offer_guid: offer_guid,
                    skill_name: e.target.name,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                },
            ])
        }
    }

    const onSelect = (e, value) => {
        setUnsavedChanges(true)

        if (value == '') return

        // if (
        //     !languageList.some((langSelected) => {
        //         setDuplicatedSkill(true)
        //         return langSelected === id_languageToCheck
        //     })
        // ) {
        //     setDuplicatedSkill(false)
        // }

        setLanguages({
            ...languages,
            [value]: true,
        })

        const updatedArray = languageList.map((skill) => {
            if (skill.skill_name === e.target.name) {
                return {
                    ...skill,
                    checked: e.target.checked,
                    lastUpdate: new Date(),
                }
            }
            return skill
        })

        if (languageList.some((item) => item.skill_name === value)) {
            setLanguageList(updatedArray)
        } else {
            setLanguageList([
                ...updatedArray,
                {
                    offer_guid: offer_guid,
                    skill_name: value,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                },
            ])
        }

        setInputValue('')
        setOfferInfo({ ...offerInfo, skill_name: null })
    }

    const handleAddSkill = async () => {
        let countChecked = languageList.filter(
            (item) =>
                item.checked === true || (item.checked === false && item?.id)
        )

        try {
            const res = await ApiService.create_multipleofferLang(countChecked)
            if (res.ok) {
                await getLanguageList()
                setUnsavedChanges(false)
                handleClose()
            } else {
                const errorText = await res.text()
                console.error(`Failed to save skills: ${errorText}`)
                // setFormErrors({ api: 'Error saving skills' })
            }
        } catch (error) {
            console.error(`Unexpected error saving skills`, error)
            // setFormErrors({ api: 'An unexpected error occurred' })
        }
    }

    const handleDelete = async (id) => {
        const res = await ApiService.delete_offerLang(id)
        if (res.ok) {
            await getLanguageList()
        } else {
            console.error(`Failed to delete skill: ${id}`, res)
        }
    }

    const availableLanguages = LanguagesTable.others.filter(
        (language) => !languageList.some((lang) => lang.skill_name === language)
    )

    useEffect(() => {
        if (Array.isArray(langList)) {
            // Temporary arrays to collect the new states
            const tempLanguages = {}
            const tempLanguageList = []

            langList.map((saved_skill) => {
                // Populate temporary objects/arrays
                tempLanguages[saved_skill.skill_name] = true
                tempLanguageList.push({
                    id: saved_skill.id,
                    offer_guid: offer_guid,
                    checked: true,
                    skill_name: saved_skill.skill_name,
                    lastUpdate: new Date(),
                })
            })

            // Set the state with the deduplicated and aggregated results
            setLanguages(tempLanguages)

            setLanguageList(tempLanguageList)
        }
    }, [langList])

    return (
        <>
            <div>
                <h3 style={{ color: '#5058ed' }}>Deve sapere queste lingue.</h3>
                <div className="section-title">
                    <p> Clicca sul + per aggiungerne altre.</p>
                    <button
                        disabled={langList?.length > 12}
                        className="icon-btn"
                        onClick={handleClickOpen}
                    >
                        <AddIcon />
                    </button>
                </div>

                {open && (
                    <div className="dropdown-form m1">
                        <div className="d-flex align-center m1">
                            <h3>Quali di queste lingue deve sapere?</h3>
                            <button
                                onClick={() => {
                                    unsavedChanges
                                        ? setConfirmOpen(true)
                                        : handleClose()
                                }}
                                className="close-card"
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div className="d-flex align-center langrecprofile">
                            <FormGroup
                                component="fieldset"
                                className="tags-check"
                            >
                                <div className="container-lanTag">
                                    {defaultLanguages.map((language) => (
                                        <p
                                            key={language}
                                            onClick={() =>
                                                handleChange({
                                                    target: {
                                                        name: language,
                                                        checked:
                                                            !languageList?.some(
                                                                (obj) =>
                                                                    obj.skill_name ===
                                                                        language &&
                                                                    obj.checked ===
                                                                        true
                                                            ),
                                                    },
                                                })
                                            }
                                            className="tags"
                                            style={{
                                                boxShadow: languageList?.some(
                                                    (obj) =>
                                                        obj.skill_name ===
                                                            language &&
                                                        obj.checked === true
                                                )
                                                    ? '0 1px 10px -3px #5058ed'
                                                    : 'none',
                                                backgroundColor:
                                                    languageList?.some(
                                                        (obj) =>
                                                            obj.skill_name ===
                                                                language &&
                                                            obj.checked === true
                                                    )
                                                        ? 'rgba(80, 88, 237, 0.1)'
                                                        : '#fff',
                                                cursor: 'pointer',
                                                width: '8rem',
                                                textAlign: 'center',
                                            }}
                                            name={language}
                                            value={language}
                                        >
                                            {language}
                                        </p>
                                    ))}

                                    {languages &&
                                        Object.keys(languages).map(
                                            (language) => {
                                                if (
                                                    !LanguagesTable.moreCommon.includes(
                                                        language
                                                    )
                                                ) {
                                                    return (
                                                        <p
                                                            key={language}
                                                            onClick={() =>
                                                                handleChange({
                                                                    target: {
                                                                        name: language,
                                                                        checked:
                                                                            !languages[
                                                                                language
                                                                            ],
                                                                    },
                                                                })
                                                            }
                                                            className="tags"
                                                            style={{
                                                                boxShadow:
                                                                    languages[
                                                                        language
                                                                    ]
                                                                        ? '0 1px 10px -3px #5058ed'
                                                                        : 'none',
                                                                backgroundColor:
                                                                    languages[
                                                                        language
                                                                    ]
                                                                        ? 'rgba(80, 88, 237, 0.1)'
                                                                        : '#fff',
                                                                cursor: 'pointer',
                                                                width: '8rem',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                            name={language}
                                                            value={
                                                                languages[
                                                                    language
                                                                ]
                                                            }
                                                        >
                                                            {language}
                                                        </p>
                                                    )
                                                }
                                            }
                                        )}
                                </div>
                            </FormGroup>
                        </div>

                        {/* {defaultLanguages.map((language) => (
                                <p
                                    key={language}
                                    onClick={() => handleChange(language)}
                                    className="tags"
                                    style={{
                                        boxShadow: languageList?.some(
                                            (obj) =>
                                                obj.skill_name === language &&
                                                obj.checked === true
                                        )
                                            ? '0 1px 10px -3px #5058ed'
                                            : 'none',
                                        backgroundColor: languageList?.some(
                                            (obj) =>
                                                obj.skill_name === language &&
                                                obj.checked === true
                                        )
                                            ? 'rgba(80, 88, 237, 0.1)'
                                            : '#fff',
                                        cursor: 'pointer',
                                        width: '8rem',
                                        textAlign: 'center',
                                    }}
                                    name={language}
                                    value={language}
                                >
                                    {language}
                                </p>
                            ))} */}
                        <div style={{ width: '90%', marginBottom: '2rem' }}>
                            {/* <Autocomplete
                                noOptionsText={'Nessun Risultato'}
                                id="grouped-demo"
                                options={languageList}
                                getOptionLabel={(option) => option}
                                onChange={onSelect}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleziona lingua *"
                                    />
                                )}
                            /> */}
                            <Autocomplete
                                id="grouped-demo"
                                noOptionsText={'Nessun Risultato'}
                                // className="mt2 select-arr mrgmob"
                                style={{ marginTop: '0.5rem' }}
                                options={availableLanguages}
                                getOptionLabel={(option) => option}
                                value={skill_name || null}
                                onChange={onSelect}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Altre lingue"
                                    />
                                )}
                                freeSolo={false} // Disable free text input
                            />
                        </div>

                        <div className="d-flex flex-column dialog-layout align-end">
                            <p className="input-error">{formErrors.api}</p>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleAddSkill()
                                }}
                                color="primary"
                                className="btn-like-mui m0"
                            >
                                Salva
                            </Button>
                        </div>
                    </div>
                )}

                <ul className="btns-card grid-2-columns skill-container tags-container">
                    {appState ? (
                        <div className="new-box">
                            <Skeleton variant="rect" width={100} height={30} />
                        </div>
                    ) : (
                        langList?.map((skill, index) => (
                            <div key={index} className="new-box">
                                <div className="d-flex align-center tags">
                                    <p>{skill.skill_name}</p>
                                    <button
                                        onClick={() => handleDelete(skill.id)}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </ul>
            </div>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default JobCardLanguages
