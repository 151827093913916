import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import React, { useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
} from '@material-ui/core'
import LanguagesTable from '../../Tables/languages_table_onboarding'
import ApiService from 'app/ApiService'

const SectionTitles = [`In quale lingua deve saper comunicare?`]

const firstGroup = ['Italiano']
const secondGroup = ['Inglese', 'Spagnolo']
const thirdGroup = ['Albanese', 'Rumeno', 'Arabo', 'Francese']

function OnboardingLangRequired({ offer, stepOnboarding, setStepOnboarding }) {
    // const [stepOnboardingLang, setStepOnboardingLang] = useState(0);
    const [isSubmit, setSubmit] = useState(false)
    const [duplicatedSkill, setDuplicatedSkill] = useState(false)
    const [languages, setLanguages] = useState(null)
    const [langList, setlangList] = useState([])
    const [appState, setAppState] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [formErrors, setFormErrors] = useState({})

    const initOfferInfo = {
        offer_guid: '',
        skill_name: '',
        created: '',
        lastUpdate: '',
    }
    const [offerInfo, setOfferInfo] = useState(initOfferInfo)

    const { offer_guid, skill_name, created, lastUpdate } = offerInfo

    const getLangList = async () => {
        try {
            const response = await ApiService.get_offersLangbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            body.map((saved_skill) => {
                // Update the languages state
                setLanguages((prevLanguages) => ({
                    ...prevLanguages,
                    [saved_skill.skill_name]: true,
                }))

                // Update the langList state
                setlangList((prevLangList) => [
                    ...prevLangList,
                    {
                        id: saved_skill.id,
                        offer_guid: offer_guid,
                        checked: true,
                        skill_name: saved_skill.skill_name,
                        lastUpdate: new Date(),
                    },
                ])
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const handleChange = (e) => {
        setLanguages({
            ...languages,
            [e.target.name]: e.target.checked,
        })

        // console.log(languages);

        const updatedArray = langList.map((skill) => {
            if (skill.skill_name === e.target.name) {
                return {
                    ...skill,
                    checked: e.target.checked,
                    lastUpdate: new Date(),
                }
            }
            return skill
        })

        if (langList.some((item) => item.skill_name === e.target.name)) {
            setlangList(updatedArray)
        } else {
            setlangList([
                ...updatedArray,
                {
                    offer_guid: offer_guid,
                    skill_name: e.target.name,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                },
            ])
        }
    }

    const onSelect = (e, value) => {
        const id_languageToCheck = value
        if (value == '') return

        if (
            !langList.some((langSelected) => {
                setDuplicatedSkill(true)
                return langSelected === id_languageToCheck
            })
        ) {
            setDuplicatedSkill(false)
        }

        setLanguages({
            ...languages,
            [value]: true,
        })

        const updatedArray = langList.map((skill) => {
            if (skill.skill_name === e.target.name) {
                return {
                    ...skill,
                    checked: e.target.checked,
                    lastUpdate: new Date(),
                }
            }
            return skill
        })

        if (langList.some((item) => item.skill_name === value)) {
            setlangList(updatedArray)
        } else {
            setlangList([
                ...updatedArray,
                {
                    offer_guid: offer_guid,
                    skill_name: value,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                },
            ])
        }

        setInputValue('')
        setOfferInfo({ ...offerInfo, skill_name: null })
    }

    const validate = () => {
        const errors = {}
        let countChecked = langList.filter((item) => item.checked === true)
        if (countChecked?.length <= 0)
            errors.lang = 'Seleziona almeno una lingua'
        return errors
    }

    async function handleNext() {
        let countChecked = langList.filter(
            (item) =>
                item.checked === true || (item.checked === false && item?.id)
        )
        const res = await ApiService.create_multipleofferLang(countChecked)
        if (res.ok) {
            setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
        }
    }

    async function getData() {
        await getLangList()
        setAppState(false)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            } else setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    // useEffect(() => {
    //     console.log(langList);
    // }, [langList])

    useEffect(() => {
        if (appState === true) getData()
    }, [appState])

    useEffect(() => {
        if (Object.keys(offer)?.length > 0) {
            setOfferInfo({ ...offerInfo, offer_guid: offer?.offer_guid })
            setAppState(true)
        }
    }, [offer])

    const availableLanguages = LanguagesTable.others.filter(
        (language) => !langList.some((lang) => lang.skill_name === language)
    )

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div className="onboarding-grid">
                    <h2 style={{ margin: '0 auto' }}>{SectionTitles[0]}</h2>
                    <h4 style={{ textAlign: 'center', marginBottom: '0rem' }}>
                        Aggiungi le lingue necessarie
                    </h4>

                    <p className="input-error text-center">{formErrors.lang}</p>

                    <div className="input-container" style={{ gap: '0rem' }}>
                        <FormControl
                            component="fieldset"
                            className="tags-check"
                        >
                            <FormGroup>
                                <div className="container-lanTag">
                                    <div className="firstchoice">
                                        {firstGroup.map((language) => (
                                            <FormControlLabel
                                                key={language}
                                                label={language}
                                                control={
                                                    <Checkbox
                                                        onChange={handleChange}
                                                        name={language}
                                                        value={language}
                                                        checked={langList?.some(
                                                            (obj) =>
                                                                obj.skill_name ===
                                                                    language &&
                                                                obj.checked ===
                                                                    true
                                                        )}
                                                    />
                                                }
                                            />
                                        ))}
                                    </div>

                                    <div className="secondchoice">
                                        {secondGroup.map((language) => (
                                            <FormControlLabel
                                                key={language}
                                                label={language}
                                                control={
                                                    <Checkbox
                                                        onChange={handleChange}
                                                        name={language}
                                                        value={language}
                                                        checked={langList?.some(
                                                            (obj) =>
                                                                obj.skill_name ===
                                                                    language &&
                                                                obj.checked ===
                                                                    true
                                                        )}
                                                    />
                                                }
                                            />
                                        ))}
                                    </div>

                                    <div className="thirdchoice">
                                        {thirdGroup.map((language) => (
                                            <FormControlLabel
                                                key={language}
                                                label={language}
                                                control={
                                                    <Checkbox
                                                        onChange={handleChange}
                                                        name={language}
                                                        value={language}
                                                        checked={langList?.some(
                                                            (obj) =>
                                                                obj.skill_name ===
                                                                    language &&
                                                                obj.checked ===
                                                                    true
                                                        )}
                                                    />
                                                }
                                            />
                                        ))}
                                    </div>

                                    <div className="newlan">
                                        {languages &&
                                            Object.keys(languages).map(
                                                (language) => {
                                                    if (
                                                        !LanguagesTable.moreCommon.includes(
                                                            language
                                                        )
                                                    ) {
                                                        return (
                                                            <FormControlLabel
                                                                key={language}
                                                                label={language}
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            languages[
                                                                                language
                                                                            ]
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        name={
                                                                            language
                                                                        }
                                                                        value={
                                                                            languages[
                                                                                language
                                                                            ]
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        )
                                                    }
                                                }
                                            )}
                                    </div>
                                </div>
                            </FormGroup>
                        </FormControl>

                        <Autocomplete
                            id="grouped-demo"
                            noOptionsText={'Nessun Risultato'}
                            // className="mt2 select-arr mrgmob"
                            style={{ marginTop: '0.5rem' }}
                            options={availableLanguages}
                            getOptionLabel={(option) => option}
                            value={skill_name || null}
                            onChange={onSelect}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue)
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Altre lingue" />
                            )}
                            freeSolo={false} // Disable free text input
                        />
                    </div>
                </div>

                <OnboardingButton
                    stepOnboarding={stepOnboarding}
                    setStepOnboarding={setStepOnboarding}
                    setSubmit={setSubmit}
                    submit={isSubmit}
                />
            </form>
        </>
    )
}

export default OnboardingLangRequired
