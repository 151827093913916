import React, { useRef } from 'react'
import { Button } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import KangratsLogo from 'app/components/Icons/KangratsLogo'
const PdfDesign = () => {
    const location = useLocation()
    const { userInfo, expList, ssList, hsList, langList, titleList, certList } =
        location.state || {}
    const componentRef = useRef()

    const handlePrint = () => {
        const printContents = componentRef.current.innerHTML
        const printWindow = window.open('', '', 'width=800,height=600')
        printWindow.document.write(`
      <html>
        <head>
          <title>nuovo_documento</title>
          <style>
            body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              font-family: Arial, sans-serif;
              background-color: #ffffff;
              margin: 0;
              padding: 0;
            }
            .pdf-container {
              padding: 20px;
            }
            .pdf-title {
              font-size: 24px;
              margin-bottom: 20px;
            }
            .pdf-button {
              display: flex;
              padding: 10px;
              border-radius: 1rem;
              align-items: center;
              justify-content: center;
              width: auto;
              color: #fff;
              font-size: 0.9375rem;
              background-color: #5058ed;
            }
            .header-pdf {
              display: grid;
              grid-template-columns: 49% 49%;
              gap: 2%;
              margin: 2rem;
              background: #5058ed;
              padding: 2rem;
              color: #fff;
              border-radius: 1rem;
            }
              .footerpdf {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 1rem 2rem;
              padding: 7px 20px;
              background: #ea6c3a;
              border-radius: 30px;
              color:#fff;
            }
            .headerfontpdf {
              color: #5058ed;
              margin-bottom: 0.5rem;
              font-weight: 700;
            }
            .subhead {
              font-weight: 600;
            }
            .flexhori {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              margin-bottom: 1rem;
            }
              ul { list-style-type: none; padding: 0; }
            .imgNameDes {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            .tags.fit {
            width: fit-content;
            color: #000;
            background:#fff;
            border-radius: 0.65rem;
            padding: 0.4rem 0.75rem;
            margin-top: 0.5rem;
            margin-right: 0.5rem;
            font-size: 0.9375rem;
            }
            .expsection {
              padding: 1rem;
              background-color: #f0f0f3;
              border-radius: 1rem;
            }
            .expcardpdf {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            }
            .girdpdf {
              display: grid;
              grid-template-columns: 49% 49%;
              gap: 2%;
              margin: 2rem;
              align-items: start;
            }
            .marbtt {
              margin-bottom: 0.5rem;
            }
            .imgpdf {
              border-radius: 50%;
              height: 6rem;
            }
            .tags{
               background:#fff;
            }
            .tags.des {
                padding: 10px 20px;
            }
            .girdup{
                gap: 1rem;
                display: grid;
            .leftsidefooter,
            .rightsidefooter {
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }
            .logopdf {
                background-image:url(../components/LandingPages/logo-fff.png);
                width: 10rem;
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
            }
}}
          </style>
        </head>
        <body>${printContents}</body>
      </html>
    `)
        printWindow.document.close()
        printWindow.print()
    }

    return (
        <div>
            <Button color="primary" onClick={handlePrint}>
                Print
            </Button>

            <div ref={componentRef}>
                <div className="header-pdf">
                    <div className="imgNameDes">
                        <div className="flexhori">
                            <img
                                className="imgpdf"
                                src={
                                    !userInfo?.photo_url ||
                                    userInfo?.photo_url === 'None'
                                        ? '/images/prof.png'
                                        : userInfo.photo_url
                                }
                                alt="Immagine profilo"
                            />
                            <h1 style={{ fontWeight: '700' }}>
                                {userInfo?.name} {userInfo?.surname}
                            </h1>
                        </div>
                        {userInfo?.description && (
                            <p
                                className="tags des"
                                style={{ borderRadius: '2rem', color: '#000' }}
                            >
                                {userInfo.description}
                            </p>
                        )}
                    </div>
                    <div className="connectpart">
                        <div>
                            <p>Contatti</p>
                            <div className="flexhori">
                                <p className="tags fit">
                                    {userInfo?.email || 'email'}
                                </p>
                                <p className="tags fit">
                                    {userInfo?.phone || 'numero'}
                                </p>
                            </div>
                        </div>
                        <div className="flexhori">
                            <div>
                                <p>In cerca di lavoro a</p>
                                <p className="tags fit">
                                    {userInfo?.location || 'località'}
                                </p>
                            </div>
                            <div>
                                <p>Aspettative salariali</p>
                                <p className="tags fit">
                                    {userInfo?.ral_type
                                        ? 'Mensile (netto)'
                                        : 'Annuo (lordo)'}{' '}
                                    {userInfo?.ral
                                        ? `${userInfo.ral} €`
                                        : 'Non Specificato'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="girdpdf">
                    {expList?.length > 0 && (
                        <div className="girdup">
                            <div className="expsection">
                                <p className="headerfontpdf">
                                    Esperienza Lavorativa
                                </p>
                                {expList.map((exp, index) => (
                                    <div key={index} className="expcardpdf">
                                        <span className="subhead">
                                            {exp.job_title}
                                        </span>
                                        <span>🏢 {exp.company_name}</span>
                                        <span>
                                            📅 {exp.job_date_start} -{' '}
                                            {exp.job_date_end}
                                        </span>
                                        <span>📍 {exp.location}</span>
                                        <span>{exp.description}</span>
                                    </div>
                                ))}
                            </div>

                            {titleList?.length > 0 && (
                                <div className="expsection">
                                    <span className="headerfontpdf">
                                        Istruzione e Formazione
                                    </span>
                                    {titleList.map((title, index) => (
                                        <div key={index} className="expcardpdf">
                                            <span className="subhead">
                                                🎓 {title.eqf_label}
                                            </span>
                                            {title.school && (
                                                <span>🏫 {title.school}</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="girdup">
                        <div className="expsection">
                            <p className="headerfontpdf">Competenze</p>

                            {langList?.length > 0 && (
                                <div className="marbtt">
                                    <p className="subhead">Lingue</p>
                                    {langList.map((lang, index) => (
                                        <p key={index} className="tags fit">
                                            {lang.skill_name}
                                        </p>
                                    ))}
                                </div>
                            )}

                            {ssList?.length > 0 && (
                                <div className="marbtt">
                                    <p className="subhead">
                                        Competenze Trasversali
                                    </p>
                                    {ssList.map((skill, index) => (
                                        <p key={index} className="tags fit">
                                            {JSON.parse(skill.skill_name)
                                                ?.soft_lv2 || ''}
                                        </p>
                                    ))}
                                </div>
                            )}

                            {hsList?.length > 0 && (
                                <div className="marbtt">
                                    <p className="subhead">
                                        Competenze Tecniche
                                    </p>
                                    {hsList.map((skill, index) => (
                                        <p key={index} className="tags fit">
                                            {skill.skill_name}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>

                        {certList?.length > 0 && (
                            <div className="expsection">
                                <p className="headerfontpdf">Certificazioni</p>
                                {certList.map((cert, index) => (
                                    <div key={index} className="expcardpdf">
                                        <span className="subhead">
                                            {cert.cert_type}
                                        </span>
                                        {cert.cert_title && (
                                            <p>✍🏻 {cert.cert_title}</p>
                                        )}
                                        {cert.date_end && (
                                            <span>
                                                📅{' '}
                                                {format(
                                                    new Date(cert.date_end),
                                                    'yyyy'
                                                )}
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="footerpdf">
                    <div className="leftsidefooter">
                        {' '}
                        <p>Realizzato con </p> <div className="logopdf"></div>
                    </div>
                    <div className="rightsidefooter">
                        <p>Salta su kangrats.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PdfDesign
