import React from 'react'
import './Styles/Main.css'
import { Router, Switch, Route, BrowserRouter } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './routes/RootRoutes'
import loginRoutes from './routes/LoginRoutes'
import AuthGuard from './auth/AuthGuard'
import AuthLoginGuard from './auth/AuthLoginGuard'
import SignupGuard from './auth/SignupGuard'
import FreeRoutingGuard from './auth/FreeRoutingGuard'
import OnboardingGuard from './auth/OnboardingGuard'
import LandingRoutingGuard from './auth/LandingRoutingGuard'
import PdfGuard from './auth/PdfGuard'
import { AuthProvider } from './contexts/FirebaseAuthContext'
import { StorageProvider } from './contexts/FirebaseStorageContext'
// import { GeoLocationProvider } from './contexts/GeolocationContext'
import { FirestoreProvider } from './contexts/FirebaseFirestoreContext'
// import { AuthJWTProvider } from './contexts/WelcomeAuthContext'
import { FirebaseFCMProvider } from './contexts/FirebaseFCMContext'
import signupRootes from './routes/SignupRootes'
import onboardingRoutes from './routes/OnboardingRoutes'
import PdfRoute from './routes/PdfRouter'
import welcomeRoutes from './routes/WelcomeRoutes'
import landingRoutes from './routes/LandingRoutes'
import { StylesProvider } from '@material-ui/core/styles'
import { MuiBottomNavigation } from '../app/Layouts/MuiBottomNavigation'

import './Styles/slick.css'
import './Styles/slick-theme.css'

// PROVE PER LO STYLE DI MATERIAL UI
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#353742',
        },
        secondary: {
            main: '#EA6C3A',
        },
    },
    typography: {
        fontFamily: 'Poppins',
    },
})

const App = () => {
    return (
        <AppContext.Provider value={{ routes }}>
            {/* <GlobalCss /> */}
            <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <Router history={history}>
                            <AuthProvider>
                                <StorageProvider>
                                    <FirestoreProvider>
                                        {/* <AuthJWTProvider> */}
                                        <FirebaseFCMProvider>
                                            {/* <GeoLocationProvider> */}
                                            <Switch>
                                                {/* landing PAGES */}
                                                {landingRoutes.map(
                                                    (item, i) => (
                                                        <LandingRoutingGuard
                                                            key={i}
                                                            path={item.path}
                                                            component={
                                                                item.component
                                                            }
                                                        />
                                                    )
                                                )}
                                                {/* Welcome PAGES */}
                                                {welcomeRoutes.map(
                                                    (item, i) => (
                                                        <FreeRoutingGuard
                                                            key={i}
                                                            path={item.path}
                                                            component={
                                                                item.component
                                                            }
                                                        />
                                                    )
                                                )}
                                                {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                                {loginRoutes.map((item, i) => (
                                                    <AuthLoginGuard
                                                        key={i}
                                                        path={item.path}
                                                        component={
                                                            item.component
                                                        }
                                                    />
                                                ))}
                                                {/* First login signup pages (choose-account, ETC.) */}
                                                {signupRootes.map((item, i) => (
                                                    <SignupGuard
                                                        key={i}
                                                        path={item.path}
                                                        component={
                                                            item.component
                                                        }
                                                    />
                                                ))}
                                                {/* Onboarding pages (onboarding, ETC.) */}
                                                {onboardingRoutes.map(
                                                    (item, i) => (
                                                        <OnboardingGuard
                                                            key={i}
                                                            path={item.path}
                                                            component={
                                                                item.component
                                                            }
                                                        />
                                                    )
                                                )}
                                                {/* AUTH PROTECTED PAGES */}
                                                {routes.map((item, i) => (
                                                    <AuthGuard
                                                        key={i}
                                                        path={item.path}
                                                        component={
                                                            item.component
                                                        }
                                                    />
                                                ))}
                                            </Switch>
                                            {/* <MuiBottomNavigation/> */}
                                            {/* </GeoLocationProvider> */}
                                        </FirebaseFCMProvider>
                                        {/* </AuthJWTProvider> */}
                                    </FirestoreProvider>
                                </StorageProvider>
                            </AuthProvider>
                        </Router>
                    </BrowserRouter>
                </StylesProvider>
            </ThemeProvider>
        </AppContext.Provider>
    )
}

export default App
