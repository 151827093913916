import React, { useEffect, useRef, useState } from 'react'
import useAuth from 'app/hooks/useAuth'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CloseIcon from 'app/components/Icons/CloseIcon'

function ModifyData() {
    const { logout } = useAuth()
    const [open, setOpen] = useState(false)
    const [applied, setApplied] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)

    const handlePasswordClick = () => {
        setOpen(true)
        setScroll('paper')
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setApplied(true)
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    return (
        <>
            <div
                className="d-flex space-between dialog-title"
                style={{ display: 'contents' }}
            >
                <DialogTitle style={{ paddingInline: 0 }}>
                    Modifica password
                </DialogTitle>
                {/* <Button
                    onClick={handleClose}
                    color="primary"
                    className="close-dialog"
                >
                    <CloseIcon />
                </Button> */}
            </div>

            {!applied ? (
                <div style={{ width: '75%', margin: 'auto' }}>
                    <p className="input-error">{formErrors.email}</p>
                    <TextField
                        label="Indirizzo mail corrente"
                        variant="filled"
                        name="email"
                        fullWidth
                        required
                        className="m1"
                        color="primary"
                        onChange={handleChange}
                    />
                    <TextField
                        label="Password corrente"
                        variant="filled"
                        name="currentPassword"
                        fullWidth
                        required
                        className="m1"
                        color="primary"
                        onChange={handleChange}
                    />
                    <TextField
                        label="Nuova password"
                        variant="filled"
                        name="newPassword"
                        fullWidth
                        required
                        className="m1"
                        color="primary"
                        onChange={handleChange}
                    />
                    <TextField
                        label="Ripeti nuova password"
                        variant="filled"
                        name="confirmNewPassword"
                        fullWidth
                        required
                        className="m1"
                        color="primary"
                        onChange={handleChange}
                    />
                    <p style={{ textAlign: 'justify' }}>
                        Una conferma verrà inviata a questo account. Clicca sul
                        link di conferma per verificare e aggiungere questo
                        indirizzo email.
                    </p>
                </div>
            ) : (
                <div>
                    <h1>Ben fatto!</h1>
                    <p>
                        Segui la procedura che hai ricevuto per mail per
                        modificare la password
                    </p>
                </div>
            )}

            <div>
                {!applied ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '2rem',
                            marginTop: '2rem',
                        }}
                    >
                        <Button
                            variant="contained"
                            className="btn-primary"
                            onClick={handleSubmit}
                            style={{ marginTop: '20px', color: '#fff' }}
                        >
                            modifica password
                        </Button>
                    </div>
                ) : (
                    <Button
                        onClick={logout}
                        className="accent-btn btn big-border-radius"
                    >
                        Continua
                    </Button>
                )}
            </div>
            {/* </Dialog> */}
        </>
    )
}

export default ModifyData
