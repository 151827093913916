import React, { useEffect } from 'react'
import history from 'history.js'

function MailCheck() {
    const goback = () => {
        try {
            history.push('/login')
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        // Trigger the Meta Pixel Lead event
        if (window.fbq) {
            window.fbq('track', 'CompleteRegistration')
        }
    }, [])

    return (
        <>
            <div className="overlay"></div>

            <main className="main-container-login">
                <div className="login-container mail-check">
                    <h1>Ben fatto</h1>
                    <p>accedi e inizia a utilizzare la nostra piattaforma</p>
                    <button
                        onClick={goback}
                        className="accent-btn btn big-border-radius"
                    >
                        Continua
                    </button>
                </div>
            </main>
        </>
    )
}

export default MailCheck
