import React, { useState, useEffect, useRef } from 'react'
import TitleDataTree from '../../Tables/title_table_container'
import Closebtnblue from '../../Icons/Closebtnblue'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import Skeleton from '@material-ui/lab/Skeleton'
import { format } from 'date-fns'

export const CompleteFormOffer = ({
    guid,
    open,
    setOpen,
    swiped = null,
    actions = false,
}) => {
    const [hsList, setHsList] = useState([])
    const [langList, setLangList] = useState([])
    const [ssList, setSsList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [certList, setCertList] = useState([])
    const [expList, setExpList] = useState([])
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)
    const [appState, setAppState] = useState(false)

    const initOffer = {
        recruiter_guid: '',
        offer_guid: '',
        company_name: '',
        job_name: '',
        sector: [],
        location: '',
        driving_license: '',
        job_percentage: 0,
        isced: '',
        ral: 0,
        ral_type: true,
        protected_category: 'false',
        photo_url: '',
        description: '',
        hs_weight: 15,
        ss_weight: 15,
        title_weight: 30,
        exp_weight: 40,
        start_job_date: '',
        job_contract: '',
        desc_company: '',
        job_type: '',
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [offerInfo, setOfferInfo] = useState(initOffer)
    const {
        recruiter_guid,
        offer_guid,
        company_name,
        job_name,
        sector,
        location,
        driving_license,
        job_percentage,
        isced,
        ral,
        ral_type,
        protected_category,
        photo_url,
        description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        start_job_date,
        job_contract,
        desc_company,
        job_type,
        created,
        lastUpdate,
    } = offerInfo

    const handleClose = () => {
        setOpen(false)
        // setOfferInfo(initOffer)
    }

    const getOfferInfo = async () => {
        try {
            const response = await ApiService.get_offerbyguid(guid)
            const body = await response.json()
            console.log(body)

            setOfferInfo(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getHsList = async () => {
        const response = await ApiService.get_offershsbyguid(guid)
        const body = await response.json()
        // console.log(body)

        try {
            setHsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLangList = async () => {
        const response = await ApiService.get_offersLangbyguid(guid)
        const body = await response.json()
        // console.log(body)

        try {
            setLangList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getSsList = async () => {
        const response = await ApiService.get_offersssbyguid(guid)
        const body = await response.json()
        // console.log(body)

        try {
            setSsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getTitleList = async () => {
        const response = await ApiService.get_offerstitlebyguid(guid)
        const body = await response.json()
        // console.log(body)

        try {
            setTitleList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getCertList = async () => {
        const response = await ApiService.get_offerCertbyguid(guid)
        const body = await response.json()
        // console.log(body)

        try {
            setCertList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getExpList = async () => {
        const response = await ApiService.get_offersexpbyguid(guid)
        const body = await response.json()
        console.log(body)

        try {
            setExpList(body)
        } catch (err) {
            console.error(err.message)
        }
    }
    //// for loading
    async function getData() {
        await getOfferInfo()
        //await new Promise((resolve) => setTimeout(resolve, 5000))
        await getHsList()
        //await new Promise((resolve) => setTimeout(resolve, 5000))
        await getLangList()
        //await new Promise((resolve) => setTimeout(resolve, 5000))
        await getSsList()
        //await new Promise((resolve) => setTimeout(resolve, 5000))
        await getTitleList()
        //await new Promise((resolve) => setTimeout(resolve, 5000))
        await getCertList()
        //await new Promise((resolve) => setTimeout(resolve, 5000))
        await getExpList()
        setAppState(false)
    }

    let getValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.key === val) {
                return obj
            }
            if (obj.children) {
                let result = getValue(obj.children, val)
                if (result) {
                    return result
                }
            }
        }
        return undefined
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (appState) getData()
    }, [appState])

    useEffect(() => {
        // console.log(guid)
        if (guid) {
            setAppState(true)
        }
    }, [guid])

    function JobDetailsSkeleton() {
        return (
            <div className="card-layout">
                <div className="text-right">
                    <section className="accent-color">
                        <Skeleton variant="text" width="50%" height={40} />
                        <Skeleton variant="text" width="30%" height={30} />
                        <div className="two-columns">
                            <Skeleton variant="text" width="40%" height={30} />
                            <div className="hideOnMobile"></div>
                            <div>
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                            </div>
                            <div>
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                            </div>
                        </div>
                        <Skeleton variant="text" width="80%" height={30} />
                    </section>
                    <section className="two-columns">
                        <div>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="text" width="40%" height={30} />
                        </div>
                        <div>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="text" width="40%" height={30} />
                        </div>
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={50} />
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={100} />
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={100} />
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={50} />
                    </section>
                    <div className="two-columns">
                        <section>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="rect" width="100%" height={50} />
                        </section>
                        <section>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="rect" width="100%" height={50} />
                        </section>
                    </div>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={100} />
                    </section>
                </div>
            </div>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="MUIDialog job-dialog"
        >
            {appState ? (
                <div className="d-flex space-between dialog-title">
                    <div
                        className="aline-img-text-skeleton"
                        id="header-img-text-offer"
                    >
                        <div className="img-fixed">
                            <Skeleton
                                className="profile-skeleton"
                                variant="circle"
                            />
                            <div className="text-center">
                                <Skeleton variant="text" width={40} />
                                <Skeleton variant="text" width={20} />
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => handleClose()}
                        color="primary"
                        className="close-dialog"
                        id="offerClosebtn"
                    >
                        <Closebtnblue />
                    </Button>
                </div>
            ) : (
                <div className="d-flex space-between dialog-title">
                    <div className="aline-img-text" id="header-img-text-offer">
                        <DialogTitle
                        // id="scroll-dialog-title"
                        >
                            <div className="img-fixed">
                                <img
                                    src={
                                        photo_url === null ||
                                        photo_url === '' ||
                                        photo_url === 'None'
                                            ? '/images/prof.png'
                                            : photo_url
                                    }
                                    alt="Immagine profilo"
                                />
                                <div className="text-center">
                                    <h3>{company_name}</h3>
                                    <p>{job_name}</p>
                                </div>
                            </div>
                        </DialogTitle>
                    </div>
                    <Button
                        onClick={() => handleClose()}
                        color="primary"
                        className="close-dialog"
                        id="offerClosebtn"
                    >
                        <Closebtnblue />
                    </Button>
                </div>
            )}

            <DialogContent dividers={scroll === 'paper'}>
                {appState ? (
                    <JobDetailsSkeleton />
                ) : (
                    <div className="card-layout">
                        <div className="text-right">
                            <section className="accent-color">
                                <h2>{job_name}</h2>
                                <div className="tags-container preview offer rec">
                                    <div className="first-side">
                                        <p className="tags">
                                            📌 {location.split(',')[0]}
                                        </p>
                                        <p className="tags">
                                            🚘{' '}
                                            {driving_license?.length > 0
                                                ? driving_license.join(', ')
                                                : 'Non Specificato'}
                                        </p>
                                    </div>
                                    <div>
                                        <h4>A partire da</h4>
                                        <p className="tags">
                                            📅{' '}
                                            {start_job_date
                                                ? format(
                                                      new Date(start_job_date),
                                                      'dd/MM/yyyy'
                                                  )
                                                : 'Non specificato'}
                                        </p>
                                    </div>
                                </div>

                                {/* <p className='tags'>{job_percentage ? (job_percentage === 100 ? "Tempo pieno" : "Part-time") : "Non specificato"}</p> */}
                                <div className="grid2culonm offer rec">
                                    <div className="first-side">
                                        <div>
                                            <h4>Offre un contratto</h4>
                                            <p className="tags">
                                                🖋️{' '}
                                                {job_contract
                                                    ? job_contract
                                                    : 'Non specificato'}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="tags">
                                                ⏱️
                                                {job_type
                                                    ? job_type
                                                    : 'Non specificato'}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        {' '}
                                        <h4>Salario</h4>
                                        <p className="tags">
                                            💰{' '}
                                            {ral
                                                ? ral + ' €'
                                                : 'Non Specificato'}{' '}
                                            <br />
                                            {ral_type
                                                ? 'Mensile (netto)'
                                                : 'Annuo (lordo)'}
                                        </p>
                                    </div>
                                </div>

                                {protected_category &&
                                protected_category === 'True' ? (
                                    <p className="italic">
                                        "Offerta riservata a categoria protetta"
                                    </p>
                                ) : null}
                            </section>

                            {desc_company && (
                                <>
                                    <section>
                                        <h4>Descrizione Azienda</h4>
                                        <p className="pre-space hyphens description">
                                            {desc_company}
                                        </p>
                                    </section>
                                </>
                            )}

                            {description && (
                                <>
                                    <section>
                                        <h4>Descrizione Ruolo</h4>
                                        <p className="pre-space hyphens descriptionitalic">
                                            {description}
                                        </p>
                                    </section>
                                </>
                            )}

                            {expList.length > 0 && (
                                <>
                                    <section className="experience-card">
                                        <h4>Esperienza Richiesta</h4>
                                        {expList.map((exp, index) => (
                                            <div key={index}>
                                                <h3>{exp.job_title}</h3>
                                                <p className="tags">
                                                    📅
                                                    {exp.years_required} anni di
                                                    esperienza
                                                </p>

                                                <p className="tags">
                                                    {exp.sector.length > 0 ? (
                                                        <p>
                                                            🏭{' '}
                                                            {exp.sector.map(
                                                                (item, idx) => (
                                                                    <span
                                                                        key={
                                                                            idx
                                                                        }
                                                                    >
                                                                        {item}
                                                                        <br />
                                                                    </span>
                                                                )
                                                            )}
                                                        </p>
                                                    ) : (
                                                        <p></p>
                                                    )}
                                                </p>
                                            </div>
                                        ))}
                                    </section>
                                </>
                            )}

                            {titleList.length > 0 && (
                                <>
                                    <section>
                                        <h4>
                                            Livello di Istruzione Desiderato
                                        </h4>
                                        {titleList.map((title, index) => {
                                            return (
                                                <div key={index}>
                                                    <h3>
                                                        🎓 {title.eqf_label}
                                                    </h3>
                                                    {title.other_title && (
                                                        <p className="bold">
                                                            🏫
                                                            {title.other_title}
                                                        </p>
                                                    )}

                                                    {/* <p>Erasmus: {title[7] ? "Sì" : "No"} </p> */}
                                                    {/* <p>Pubblicazioni: {title[9] ? "Sì" : "No"} </p> */}
                                                </div>
                                            )
                                        })}
                                    </section>
                                </>
                            )}
                            <section>
                                <h4>Competenze</h4>
                                {langList.length > 0 && (
                                    <>
                                        <div>
                                            <h3>Competenze Linguistiche</h3>
                                            <ul className="tags-container">
                                                {langList.map(
                                                    (skill, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="tags"
                                                            >
                                                                {
                                                                    skill.skill_name
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </>
                                )}
                                <div className="two-columns">
                                    {ssList.length > 0 && (
                                        <>
                                            <div>
                                                <h3>Competenze Trasversali</h3>
                                                <ul className="tags-container">
                                                    {ssList.map(
                                                        (skill, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="tags"
                                                                >
                                                                    {' '}
                                                                    {
                                                                        JSON.parse(
                                                                            skill.skill_name
                                                                        )
                                                                            ?.soft_lv2
                                                                    }{' '}
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                    {hsList.length > 0 && (
                                        <>
                                            <div>
                                                <h3>Competenze Tecniche</h3>
                                                <ul className="tags-container">
                                                    {hsList.map(
                                                        (skill, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="tags"
                                                                >
                                                                    {
                                                                        skill.skill_name
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </section>

                            {certList.length > 0 && (
                                <>
                                    <section>
                                        <h4>Certificazioni richieste</h4>
                                        {certList.map((title, index) => {
                                            return (
                                                <div key={index}>
                                                    <h3 className="text-ellipsis-2">
                                                        {title.cert_type}
                                                    </h3>
                                                    {title.cert_title && (
                                                        <p>
                                                            ✍🏻{' '}
                                                            {title.cert_title}
                                                        </p>
                                                    )}
                                                    <p className="placeholder-text descrition-lines">
                                                        {title.description}
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </section>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </DialogContent>
            {actions ? (
                <DialogActions
                    className={[
                        'dialog-button-container',
                        appState ? 'hide' : 'show',
                    ]}
                >
                    <Button
                        color="primary"
                        style={{ background: '#5058ed' }}
                        onClick={() => {
                            swiped(0, offer_guid, recruiter_guid)
                            handleClose()
                        }}
                    >
                        <span className="dialog-button-text blue">
                            Non mi piace
                        </span>
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            swiped(1, offer_guid, recruiter_guid, job_name)
                            handleClose()
                        }}
                    >
                        <span className="dialog-button-text">Mi piace</span>
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions />
            )}
        </Dialog>
    )
}
