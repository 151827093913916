import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { authRoles } from '../auth/authRoles'
import PdfDesign from 'app/components/Homepage/CompleteForm/Pdfdesign'
import PreviewCardJob from 'app/components/Homepage/ProfileComponents/JobCard/PreviewCardJob'
import PdfCandidato from 'app/components/Homepage/CompleteForm/Pdfcandidato'
import Profile from 'app/components/Homepage/Profile'
const PdfRoute = [
    {
        path: '/popup',
        component: PdfDesign,
        auth: authRoles.candidate,
    },
]

export default PdfRoute
