import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    FormGroup,
} from '@material-ui/core'
import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import PlaceAutocomplete from '../../PlaceAutocomplete/TomTomPlaceAutocomplete'
import ApiService from 'app/ApiService'
import Autocomplete from '../../Autocomplete/AsyncAutocomplete'

const patenteData = {
    firstGroup: ['Auto/Moto'],
    secondGroup: ['Camion', 'Gru'],
    thirdGroup: ['Autobus', 'Muletto'],
    fourthGroup: ['Rimorchio', 'Nautica'],
}

const SectionTitles = [
    'In che ruolo o mansione?',
    'La cerco a',
    'I requisiti della figura che cerchi',
]
const jobTypeOptions = ['In sede', 'Ibrido', 'Remoto']

const firstGroupLocations = [
    { label: 'Milano', geolocation: { lat: 45.463618, lon: 9.188116 } },
]
const secondGroupLocations = [
    { label: 'Monza', geolocation: { lat: 45.583132, lon: 9.272965 } },
    { label: 'Sesto S.G.', geolocation: { lat: 45.535858, lon: 9.238889 } },
    {
        label: 'Cologno Monzese',
        geolocation: { lat: 45.529464, lon: 9.278397 },
    },
]
const thirdGroupLocations = [
    { label: 'Rho', geolocation: { lat: 45.529783, lon: 9.038125 } },
    {
        label: 'Cinisello Balsamo',
        geolocation: { lat: 45.554021, lon: 9.224253 },
    },
    { label: 'Segrate', geolocation: { lat: 45.501145, lon: 9.291694 } },
]

function OnboardingInfoJob({
    userInfo,
    offer,
    getOffer,
    stepOnboarding,
    setStepOnboarding,
}) {
    const [stepOnboardingInfo, setStepOnboardingInfo] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})

    const initOffer = {
        recruiter_guid: userInfo?.recruiter_guid || '',
        offer_guid: '',
        company_name: userInfo?.company_name || '',
        job_name: '',
        sector: userInfo?.sector,
        location: '',
        driving_license: [],
        job_type: '',
        job_contract: '',
        protected_category: null,
        job_percentage: 0,
        isced: '',
        ral: 800,
        ral_type: true,
        description: '',
        hs_weight: 15,
        ss_weight: 15,
        title_weight: 30,
        exp_weight: 40,
        start_job_date: '',
        created: new Date(),
        lastUpdate: new Date(),
        active: true,
    }
    const [offerInfo, setOfferInfo] = useState(initOffer)
    const {
        recruiter_guid,
        offer_guid,
        job_name,
        sector,
        location,
        geolocation,
        driving_license,
        job_type,
        job_contract,
        protected_category,
        job_percentage,
        isced,
        ral,
        ral_type,
        description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        created,
        start_job_date,
        lastUpdate,
        active,
    } = offerInfo

    const onChange = (event) => {
        event.preventDefault()
        setOfferInfo({
            ...offerInfo,
            [event.target.name]: event.target.value,
        })
    }

    const setGeoLocationInfo = (place, geolocation) => {
        setOfferInfo({
            ...offerInfo,
            location: place,
            geolocation: geolocation,
        })
    }

    const allLocations = [
        ...firstGroupLocations,
        ...secondGroupLocations,
        ...thirdGroupLocations,
    ]

    const handleRadiolocationChange = (event) => {
        const selectedLabel = event.target.value // Get the label (string) of the selected location

        // Find the full location object based on the selected label
        const selectedLocation = allLocations.find(
            (loc) => loc.label === selectedLabel
        )
        // console.log(selectedLocation)
        setOfferInfo((prevUserInfo) => ({
            ...prevUserInfo,
            location: selectedLocation.label,
            geolocation: selectedLocation.geolocation,
        }))
    }

    const handleDrivingLicenseChange = (event) => {
        const { value } = event.target

        setOfferInfo((prevUserInfo) => {
            const currentLicenses = prevUserInfo.driving_license || [] // Initialize as empty array if not present
            let updatedLicenses

            if (currentLicenses.includes(value)) {
                // If the value is already in the array, remove it
                updatedLicenses = currentLicenses.filter(
                    (license) => license !== value
                )
            } else {
                // If the value is not in the array, add it
                updatedLicenses = [...currentLicenses, value]
            }

            return {
                ...prevUserInfo,
                driving_license: updatedLicenses,
            }
        })
    }
    const handleProtectedCategoryChange = (value) => {
        setOfferInfo({
            ...offerInfo,
            protected_category: value,
        })
    }

    async function handleNext() {
        if (stepOnboardingInfo >= SectionTitles.length - 1) {
            const res = await ApiService.create_offer(offerInfo)
            if (!res.ok) return //show error
            else {
                await getOffer()
                setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
            }
        }
        setStepOnboardingInfo((prevActiveStep) =>
            prevActiveStep !== SectionTitles.length - 1
                ? prevActiveStep + 1
                : prevActiveStep
        )
        setSubmit(false)
    }

    const validate = () => {
        const errors = {}

        switch (stepOnboardingInfo) {
            case 0:
                if (!job_name)
                    errors.job_name = 'Questo campo non può essere nullo'
                if (!job_type)
                    errors.job_type = 'Questo campo non può essere nullo'

                break
            case 1:
                if (!location)
                    errors.location = 'Questo campo non può essere nullo'
                break
            case 2:
                if (protected_category === null)
                    errors.protected_category = 'Devi selezionare una opzione'
                break
        }
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            } else setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    useEffect(() => {
        setOfferInfo({
            ...offerInfo,
            recruiter_guid: userInfo?.recruiter_guid,
            company_name: userInfo?.company_name,
            sector: userInfo?.sector,
        })
    }, [userInfo])

    useEffect(() => {
        if (Object.keys(offer)?.length > 0) {
            setOfferInfo(offer)
        }
    }, [offer])

    useLayoutEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'instant', // You can also use 'auto' or 'instant'
        })
    }, [stepOnboardingInfo])

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className="onboarding-grid">
                <h3
                    className="m1"
                    style={{
                        fontWeight: '700',
                        textAlign: 'center',
                        marginTop: '0rem',
                    }}
                >
                    {SectionTitles[stepOnboardingInfo]}
                </h3>
                {(() => {
                    switch (stepOnboardingInfo) {
                        case 0:
                            return (
                                <>
                                    <div className="input-container">
                                        <div>
                                            <p
                                                className="input-error"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {formErrors.job_name}
                                            </p>
                                            <Autocomplete
                                                parentValue={job_name}
                                                onChange={(newValue) =>
                                                    setOfferInfo({
                                                        ...offerInfo,
                                                        job_name: newValue,
                                                    })
                                                }
                                                label={
                                                    'es: barista, addetto vendite..'
                                                }
                                                apiRequest={
                                                    ApiService.get_joblist
                                                }
                                                required
                                            />
                                        </div>

                                        {/* <div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    label="Data di inizio desiderata"
                                                    format="dd/MM/yyyy"
                                                    value={start_job_date || null}
                                                    autoOk
                                                    onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    fullWidth                                                    
                                                    disablePast
                                                    invalidDateMessage=''
                                                    maxDateMessage=""
                                                    minDateMessage=""
                                                />
                                            </MuiPickersUtilsProvider>
                                            <p className='input-error'>{formErrors.start_job_date}</p>
                                        </div> */}

                                        <div style={{ textAlign: 'center' }}>
                                            <FormControl
                                                component="fieldset"
                                                className="tags-check"
                                            >
                                                <h3
                                                    style={{
                                                        margin: '2rem auto 1rem',
                                                        fontWeight: '700',
                                                    }}
                                                >
                                                    {' '}
                                                    La modalità di lavoro è
                                                </h3>
                                                <p
                                                    className="input-error"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {formErrors.job_type}
                                                </p>
                                                <FormGroup row>
                                                    {jobTypeOptions.map(
                                                        (type) => (
                                                            <FormControlLabel
                                                                key={type}
                                                                control={
                                                                    <Checkbox
                                                                        name="job_type"
                                                                        checked={
                                                                            job_type ===
                                                                            type
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            onChange(
                                                                                e
                                                                            )
                                                                        }
                                                                        value={
                                                                            type
                                                                        }
                                                                    />
                                                                }
                                                                label={type}
                                                            />
                                                        )
                                                    )}
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </>
                            )
                        case 1:
                            return (
                                <>
                                    <div className="input-container location">
                                        <FormControl
                                            component="fieldset"
                                            className="tags-check"
                                        >
                                            {/* <h3
                                                className="m1"
                                                style={{
                                                    textAlign: 'center',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                La cerco a
                                            </h3> */}

                                            <FormGroup>
                                                <p className="input-error onboarding">
                                                    {formErrors.location}
                                                </p>
                                                <RadioGroup
                                                    name="location"
                                                    value={
                                                        location.split(',')[0]
                                                    }
                                                    onChange={
                                                        handleRadiolocationChange
                                                    }
                                                >
                                                    <div className="container-lanTag">
                                                        <div className="firstchoice location">
                                                            {firstGroupLocations.map(
                                                                (loc) => (
                                                                    <FormControlLabel
                                                                        key={
                                                                            loc.label
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            loc.label
                                                                        }
                                                                        value={
                                                                            loc.label
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="secondchoice location">
                                                            {secondGroupLocations.map(
                                                                (loc) => (
                                                                    <FormControlLabel
                                                                        key={
                                                                            loc.label
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            loc.label
                                                                        }
                                                                        value={
                                                                            loc.label
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="thirdchoice location">
                                                            {thirdGroupLocations.map(
                                                                (loc) => (
                                                                    <FormControlLabel
                                                                        key={
                                                                            loc.label
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            loc.label
                                                                        }
                                                                        value={
                                                                            loc.label
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </RadioGroup>
                                            </FormGroup>
                                        </FormControl>
                                    </div>

                                    <div className="input-container location2">
                                        <h3
                                            className="m1"
                                            style={{
                                                fontWeight: '700',
                                                textAlign: 'center',
                                                marginBottom: '0rem',
                                                marginTop: '1rem',
                                                gap: '0rem',
                                            }}
                                        >
                                            Cerchi in altre località?
                                        </h3>
                                        <PlaceAutocomplete
                                            className="input-onboarding"
                                            setSetlected={setGeoLocationInfo}
                                            defaultValue={
                                                allLocations.filter(
                                                    (x) =>
                                                        x.label ==
                                                        location.split(',')[0]
                                                ).length <= 0
                                                    ? location
                                                    : null
                                            }
                                            label={
                                                'Luogo dove vorresti cercare una risorsa'
                                            }
                                            required={false}
                                            isCandidate={true}
                                        />
                                    </div>
                                </>
                            )

                        case 2:
                            return (
                                <>
                                    <div
                                        className="input-container"
                                        style={{ gap: 0 }}
                                    >
                                        <div className="input-container location">
                                            <h4
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Seleziona tutte le patenti
                                                necessarie
                                            </h4>
                                            <FormControl
                                                component="fieldset"
                                                className="tags-check"
                                            >
                                                <FormGroup>
                                                    <div className="container-lanTag driving">
                                                        <div className="firstchoice driving">
                                                            {patenteData.firstGroup.map(
                                                                (license) => (
                                                                    <FormControlLabel
                                                                        key={
                                                                            license
                                                                        }
                                                                        control={
                                                                            <Checkbox
                                                                                checked={driving_license?.includes(
                                                                                    license
                                                                                )}
                                                                                onChange={
                                                                                    handleDrivingLicenseChange
                                                                                }
                                                                                value={
                                                                                    license
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            license
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="secondchoice driving">
                                                            {patenteData.secondGroup.map(
                                                                (license) => (
                                                                    <FormControlLabel
                                                                        key={
                                                                            license
                                                                        }
                                                                        control={
                                                                            <Checkbox
                                                                                checked={driving_license?.includes(
                                                                                    license
                                                                                )}
                                                                                onChange={
                                                                                    handleDrivingLicenseChange
                                                                                }
                                                                                value={
                                                                                    license
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            license
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="thirdchoice driving">
                                                            {patenteData.thirdGroup.map(
                                                                (license) => (
                                                                    <FormControlLabel
                                                                        key={
                                                                            license
                                                                        }
                                                                        control={
                                                                            <Checkbox
                                                                                checked={driving_license?.includes(
                                                                                    license
                                                                                )}
                                                                                onChange={
                                                                                    handleDrivingLicenseChange
                                                                                }
                                                                                value={
                                                                                    license
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            license
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="fourthchoice driving">
                                                            {patenteData.fourthGroup.map(
                                                                (license) => (
                                                                    <FormControlLabel
                                                                        key={
                                                                            license
                                                                        }
                                                                        control={
                                                                            <Checkbox
                                                                                checked={driving_license?.includes(
                                                                                    license
                                                                                )} // Ensure it reflects selection
                                                                                onChange={
                                                                                    handleDrivingLicenseChange
                                                                                } // Handle change
                                                                                value={
                                                                                    license
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            license
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                        <FormControl
                                            component="fieldset"
                                            className="radio-groups mt1 signup"
                                        >
                                            <FormLabel
                                                required
                                                component="h3"
                                                style={{
                                                    fontWeight: '700',
                                                    textAlign: 'center',
                                                    marginBottom: '0rem',
                                                    marginTop: '1rem',
                                                    fontSize: '1.125rem',
                                                }}
                                            >
                                                L'offerta di lavoro è dedicata a
                                                una categoria protetta?
                                            </FormLabel>
                                            <p className="input-error onboarding">
                                                {formErrors.protected_category}
                                            </p>
                                            <div className="switchOnBoarding signup">
                                                <div
                                                    onClick={() =>
                                                        handleProtectedCategoryChange(
                                                            true
                                                        )
                                                    }
                                                    style={{
                                                        padding: '10px 20px',
                                                        border: '1px solid #ccc',
                                                        width: '6rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        backgroundColor:
                                                            protected_category ===
                                                            true
                                                                ? '#ea6c3a'
                                                                : 'transparent',
                                                        color:
                                                            protected_category ===
                                                            true
                                                                ? 'white'
                                                                : 'black',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Si
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        handleProtectedCategoryChange(
                                                            false
                                                        )
                                                    }
                                                    style={{
                                                        padding: '10px 20px',
                                                        border: '1px solid #ccc',
                                                        width: '6rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        backgroundColor:
                                                            protected_category ===
                                                            false
                                                                ? '#ea6c3a'
                                                                : 'transparent',
                                                        color:
                                                            protected_category ===
                                                            false
                                                                ? 'white'
                                                                : 'black',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    No
                                                </div>
                                            </div>
                                        </FormControl>
                                    </div>
                                </>
                            )
                        // case 1:
                        //     return (
                        //         <>
                        //             <div className="input-container">

                        //                 {/* <h4>Descrivi le maggiori responsabilità</h4>
                        //                 <TextField
                        //                     label="Compila qui"
                        //                     variant="filled"
                        //                     fullWidth
                        //                     color="primary"
                        //                     name='description'
                        //                     value={description}
                        //                     onChange={onChange}
                        //                     multiline
                        //                     rows={3}
                        //                 /> */}
                        //             </div>
                        //         </>
                        //     )
                    }
                })()}
            </div>

            <OnboardingButton
                stepOnboarding={stepOnboarding}
                setStepOnboarding={setStepOnboarding}
                setSubmit={setSubmit}
                submit={isSubmit}
            />
        </form>
    )
}

export default OnboardingInfoJob
