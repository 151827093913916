import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import history from 'history.js'
import useAuth from 'app/hooks/useAuth'
import { authRoles } from 'app/auth/authRoles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function ItsMatch({ imageSrc, setOpen }) {
    const { pathname } = useLocation()
    const [sentence, setSentence] = useState('')
    const [path, setPath] = useState('')
    const { user, role } = useAuth()
    const isMobile = useMediaQuery('(max-width:500px)')
    const handleBackClick = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (authRoles.candidate.includes(role)) {
            setSentence('CON UN’OFFERTA')
            setPath('/match')
        } else if (authRoles.recruiter.includes(role)) {
            setSentence('CON UN CANDIDATO')
            setPath('/match-recruiter')
        }
    }, [pathname])

    return (
        <Box
            sx={{
                backgroundColor: '#ea6c3a',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                fontFamily: "'Poppins', sans-serif",
            }}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleBackClick()}
                className="backbtn"
            >
                <ArrowBackIosIcon />
            </Button>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                    border: '4px solid #fff',
                    borderRadius: '50%',
                    fontFamily: "'Poppins', sans-serif",
                    marginTop: isMobile ? '-7rem' : '0',
                }}
            >
                <img
                    src={imageSrc}
                    alt="Profile Match"
                    style={{
                        borderRadius: '50%',
                        width: '150px',
                        height: '150px',
                        fontFamily: "'Poppins', sans-serif",
                    }}
                />
            </Box>

            <Typography
                variant="h4"
                sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: '1.5rem',
                }}
            >
                Kangrats!
            </Typography>

            <Typography
                variant="h5"
                sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'white',
                    padding: '10px 20px',
                    borderRadius: '10px',
                    color: '#f26531',
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    lineHeight: '1.4',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    marginBottom: '30px',
                    fontFamily: "'Poppins', sans-serif",
                }}
            >
                HAI FATTO MATCH <br /> {sentence}
            </Typography>

            <Button
                variant="outlined"
                sx={{
                    borderColor: 'white',
                    color: 'white',
                    padding: '10px 20px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    borderRadius: '25px',
                    fontSize: '1rem',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: '#f26531',
                    },
                }}
                onClick={() => history.push(path)}
            >
                Entra in contatto ora!
            </Button>
        </Box>
    )
}

export default ItsMatch
