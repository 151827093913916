import React, { useEffect, useRef, useState } from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    TextareaAutosize,
    TextField,
    DialogContentText,
    Switch,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import title_type_onboarding from '../../../Tables/title_type_onboarding'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Container from '../../../TreeSelect/DropdownContainer'
import TitleDataTree from '../../../Tables/title_table_container'
import ApiService from 'app/ApiService'
import title_type from '../../../Tables/title_type'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import AddIcon from 'app/components/Icons/AddIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import BinIcon from 'app/components/Icons/BinIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Closebtnblue from 'app/components/Icons/Closebtnblue'
import Box from '@material-ui/core/Box'
import years_ranges from '../../../Tables/years_ranges'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Autocomplete from '../../../Autocomplete/AsyncAutocomplete'
import EditFieldDialog from '../FooterProfile/EditField'
import { Tune } from '@material-ui/icons'

function JobCardEducation({ titlelist, offerguid, getTitleList, loading }) {
    // per dialog
    const descriptionElementRef = useRef(null)
    // per dialog di conferma (vuoi eliminare definitivamente?)
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const [open, setOpen] = useState(false)
    const [dialogText, setDialogText] = useState('Aggiungi')
    const [titleList, setTitleList] = useState([])
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [titleObjects, setTitleObjects] = useState([])
    const [showMore, setShowMore] = useState(false)
    const [showCards, setShowCards] = useState(true)
    // const [localOtherTitle, setLocalOtherTitle] = useState('')
    const [isSwitchEDChecked, setIsSwitchEDChecked] = useState(
        titleList.length === 0
    )
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const initUserInfo = {
        offer_guid: offerguid || '',
        eqf_label: '',
        eqf_value: 0,
        // title_name: [""],
        title_name: [],
        title_label: [],
        other_title: '',
        erasmus: 'false',
        publications: 'false',
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        offer_guid,
        eqf_label,
        eqf_value,
        title_name,
        title_label,
        other_title,
        erasmus,
        publications,
        created,
        lastUpdate,
    } = userInfo

    const handleClickOpen = () => () => {
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: false,
        }))
        setTitleObjects(newTitleObjects)

        setDialogText('Aggiungi')
        setOpen(true)
        setUserInfo({
            ...initUserInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClickUpdateDialogOpen = (titleInfo, index) => () => {
        setOpen(false)
        toggleOpen(index)
        setDialogText('Aggiorna')
        setUserInfo({
            ...userInfo,
            id: titleInfo.id,
            title_name: JSON.parse(titleInfo.title_name),
            other_title: titleInfo.other_title,
            eqf_label: titleInfo.eqf_label,
            eqf_value: titleInfo.eqf_value,
            erasmus: titleInfo.erasmus.toString(),
            // publications: titleInfo[7].toString(),
            lastUpdate: new Date(),
        })
    }

    const handleClose = (index) => {
        toggleOpen(index)
        setOpen(false)
        // resetValue(TitleDataTree)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    const onEqfSelect = (event) => {
        setIsEdited(true)
        const val = title_type.find(
            (x) =>
                x.label.toLowerCase() ===
                event.target.value.trim().toLowerCase()
        )
        if (val.value > 3)
            setUserInfo({
                ...userInfo,
                eqf_label: val.label,
                eqf_value: val.value,
            })
        else
            setUserInfo({
                ...userInfo,
                eqf_label: val.label,
                eqf_value: val.value,
                other_title: null,
                title_name: [],
                parent_title: null,
            })
    }

    const handleAddTitle = async () => {
        const res = await ApiService.create_offertitle(userInfo)
        if (res.ok) getTitleList()
        handleClose()
    }

    const handleDelete = async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_offertitle(userInfo.id)
        if (res.ok) getTitleList()
        setOpenConfirm(false)
    }

    const handleSubmit = async () => {
        const res = await ApiService.update_offertitle(userInfo)
        if (res.ok) getTitleList()
        handleClose()
    }

    const handleClickOpenConfirm = (id) => {
        setOpenConfirm(true)
        setUserInfo({
            ...userInfo,
            id: id,
        })
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    const validate = () => {
        const errors = {}
        if (!eqf_label) errors.eqf_label = 'Questo campo non può essere nullo'
        return errors
    }

    const toggleOpen = (index) => {
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: i === index ? !item.open : false,
        }))
        setTitleObjects(newTitleObjects)
    }

    const onSelect = (value) => {
        // console.log(value)
        setIsEdited(true)
        setUserInfo({
            ...userInfo,
            other_title: value,
        })
        // console.log(userInfo)
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }

    const handleSwitchToggle = () => {
        if (!isSwitchEDChecked) {
            setOpenConfirm(true)
        } else {
            setIsSwitchEDChecked(false)
        }
    }

    const handleConfirmnoskillsProceed = async () => {
        setOpenConfirm(false)
        const res = await ApiService.delete_alloffertitles(offer_guid)
        if (res.ok) {
            getTitleList()
        } else {
            console.error('Error deleting experience entries')
        }
    }
    const handleConfirmnoskillsClose = () => {
        setOpenConfirm(false)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0)
                if (dialogText === 'Aggiungi') handleAddTitle()
                else handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        setTitleList(titlelist)

        const newTitleObjects = titlelist.map((_, index) => ({
            open: false,
            index: index,
        }))
        setTitleObjects(newTitleObjects)

        setIsSwitchEDChecked(titlelist.length === 0)
    }, [titlelist])

    // useEffect(() => {
    //     if (userInfo.other_title) {
    //         setTitleList((prevList) =>
    //             prevList.map((title) => ({
    //                 ...title,
    //                 other_title: userInfo.other_title,
    //             }))
    //         )
    //     }
    // }, [userInfo.other_title])

    const renderForm = (index) => {
        return (
            <div className="dropdown-form profilerec edu">
                <div className="d-flex align-center m1">
                    <h3>Livello di istruzione richiesto al candidato</h3>
                    <button
                        onClick={handleDialogClose}
                        // onClick={() => handleClose(index)}
                        className="close-card"
                    >
                        <CloseIcon />
                    </button>
                </div>

                {/* <div className="dialog-layout m0"> */}
                <div>
                    <h5>E’ richiesto alcun titolo di studio?</h5>
                    <p className="input-error">{formErrors.eqf_label}</p>
                    <FormControl
                        component="fieldset"
                        className="tags-check"
                        style={{ width: '100%' }}
                    >
                        <RadioGroup
                            aria-label="Istruzione"
                            name="istruzione"
                            value={eqf_label}
                            onChange={onEqfSelect}
                        >
                            <div className="container-Study">
                                <div className="first-choice-container">
                                    <FormControlLabel
                                        key={
                                            title_type_onboarding.show[0].label
                                        }
                                        value={
                                            title_type_onboarding.show[0].label
                                        }
                                        control={<Radio />}
                                        label={
                                            title_type_onboarding.show[0].label
                                        }
                                        className="large-button"
                                    />
                                </div>
                                <div className="educationOtherChoice">
                                    <div className="scuole-and-triennale-container profrec">
                                        <FormControlLabel
                                            key={
                                                title_type_onboarding.show[1]
                                                    .label
                                            }
                                            value={
                                                title_type_onboarding.show[1]
                                                    .label
                                            }
                                            control={<Radio />}
                                            label={
                                                title_type_onboarding.show[1]
                                                    .label
                                            }
                                            className="scuole-medie-button"
                                        />
                                        <FormControlLabel
                                            key={
                                                title_type_onboarding.show[2]
                                                    .label
                                            }
                                            value={
                                                title_type_onboarding.show[2]
                                                    .label
                                            }
                                            control={<Radio />}
                                            label={
                                                title_type_onboarding.show[2]
                                                    .label
                                            }
                                            className="laurea-triennale-button"
                                        />
                                    </div>

                                    <div className="other-choices-container">
                                        <FormControlLabel
                                            key={
                                                title_type_onboarding.show[3]
                                                    .label
                                            }
                                            value={
                                                title_type_onboarding.show[3]
                                                    .label
                                            }
                                            control={<Radio />}
                                            label={
                                                title_type_onboarding.show[3]
                                                    .label
                                            }
                                            className="custom-radio-label"
                                        />
                                        <div>
                                            <button
                                                onClick={() => {
                                                    setShowMore(!showMore)
                                                }}
                                                className="underline-text"
                                                style={{
                                                    backgroundColor:
                                                        'transparent',
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    outline: 'none',
                                                    color: '#4b4c55',
                                                    fontWeight: '600',
                                                    gap: '0',
                                                }}
                                            >
                                                Altro{' '}
                                                {showMore ? (
                                                    <ArrowDropUpIcon />
                                                ) : (
                                                    <ArrowDropDownIcon />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="show-more-grid">
                                {showMore &&
                                    title_type_onboarding.showMore.map(
                                        (title) => (
                                            <FormControlLabel
                                                key={title.label}
                                                value={title.label}
                                                control={<Radio />}
                                                label={title.label}
                                            />
                                        )
                                    )}
                            </div>
                        </RadioGroup>
                    </FormControl>

                    {/* <FormControl variant="filled" fullWidth>
                            <InputLabel
                                required
                                htmlFor="filled-age-native-simple"
                            >
                                Tipologia titolo
                            </InputLabel>
                            <Select
                                native
                                fullWidth
                                required
                                name="eqf"
                                value={eqf_label}
                                onChange={onEqfSelect}
                                inputProps={{
                                    name: 'eqf',
                                    // id: 'filled-age-native-simple',
                                }}
                            >
                                <option hidden aria-label="None" value={null} />
                                {title_type.map((title) => {
                                    return (
                                        <option
                                            key={title.label}
                                            value={title.label}
                                        >
                                            {title.label}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl> */}
                </div>
                <div className="dialog-layout m0">
                    {eqf_value >= 3 && (
                        <div className="input-container " style={{ gap: '0' }}>
                            <h5>In che percorso di studi?</h5>
                            <div>
                                <p className="input-error text-center onboarding">
                                    {formErrors.other_title}
                                </p>
                                <Autocomplete
                                    parentValue={other_title}
                                    allowNewOption={false}
                                    onChange={onSelect}
                                    label={'Percorso di studi'}
                                    apiRequest={(newValue) =>
                                        ApiService.get_titlelist(
                                            newValue,
                                            eqf_value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
                {/* </div> */}

                {/* {title_name.length > 0 && (
                    <div className="options-selected-container mt1">
                        {title_name.map((titleSelected, index) => (
                            <div
                                className="d-flex align-center tags new-box tags-container"
                                key={index}
                            >
                                <p>
                                    {' '}
                                    {
                                        getValue(TitleDataTree, titleSelected)
                                            ?.label
                                    }
                                </p>
                                <button
                                    className="tag-closebtn"
                                    onClick={(e) => {
                                        handleDeleteTitle(e, index)
                                    }}
                                >
                                    <Closebtnblue />
                                </button>
                            </div>
                        ))}
                    </div>
                )} */}

                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        setSubmit(true)
                    }}
                    color="primary"
                    className="btn-like-mui"
                >
                    {dialogText}
                </Button>
            </div>
        )
    }

    return (
        <>
            <div className="eduRecprof">
                <div className="section-title">
                    <h3 style={{ marginBottom: 0 }}>
                        Ecco la tipologia e il livello di istruzione che il tuo
                        candidato deve avere.{' '}
                    </h3>
                    {/* <button className="icon-btn" onClick={handleClickOpen()}>
                        <AddIcon />
                    </button> */}
                </div>
                <div className="box-preview eduRecprof section-title">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 0,
                            marginTop: 0,
                        }}
                    >
                        <FormControlLabel
                            label="Non è richiesta istruzione pregressa"
                            labelPlacement="start"
                            sx={{
                                justifyContent: 'space-between',
                                '.MuiFormControlLabel-label': {
                                    color: '#333333',
                                    fontWeight: 'bold',
                                },

                                '&.MuiFormControlLabel-root': {
                                    marginLeft: 0,
                                },
                            }}
                            control={
                                <Switch
                                    checked={isSwitchEDChecked}
                                    onChange={handleSwitchToggle}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: '#FFFFFF',
                                            '&:hover': {
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.08)',
                                            },
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                            {
                                                backgroundColor: '#F06031',
                                            },
                                        '& .MuiSwitch-switchBase': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: '#EEEEEE',
                                        },
                                        '& .MuiSwitch-thumb': {
                                            boxShadow: 'none',
                                        },
                                    }}
                                />
                            }
                        />
                    </Box>

                    {titleList?.length < 1 && !isSwitchEDChecked && (
                        <button
                            className="icon-btn"
                            onClick={handleClickOpen()}
                        >
                            <AddIcon />
                        </button>
                    )}
                </div>
                {/* {titleList.length <= 0 && !open && !loading && (
                    <div className="p1">
                        <div className="placeholder-card">
                            <div className="bg-el"></div>
                            <div className="d-flex flex-column justify-center align-center text-center h-100">
                                <p className="accent-text-color">
                                    Inserisci le competenze tecniche richieste
                                    per svolgere il ruolo
                                </p>
                                <button
                                    onClick={handleClickOpen()}
                                    className="primary-text-color"
                                >
                                    Inizia qui
                                </button>
                            </div>
                        </div>
                    </div>
                )} */}

                {open && renderForm()}

                <div className="box-preview">
                    {showCards &&
                        titleList.map((title, index) => {
                            return (
                                <div
                                    key={index}
                                    className="card-preview new-box"
                                >
                                    {titleObjects[index].open ? (
                                        renderForm(index)
                                    ) : (
                                        <div className="required-card">
                                            <div className="d-flex actions-btns">
                                                <button
                                                    onClick={handleClickUpdateDialogOpen(
                                                        title,
                                                        index
                                                    )}
                                                    className="icon-btn"
                                                >
                                                    <EditIcon />
                                                </button>
                                                <button
                                                    className="icon-btn"
                                                    onClick={() =>
                                                        handleClickOpenConfirm(
                                                            title.id
                                                        )
                                                    }
                                                >
                                                    <BinIcon />
                                                </button>
                                            </div>
                                            <div>
                                                <p className="tags">
                                                    🎓{title.eqf_label}{' '}
                                                </p>
                                                <p className="tags">
                                                    📚 {title.other_title}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                </div>
            </div>

            {/* DIALOG PER ELIMINARE LA CARD */}

            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Sicuro?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler eliminare questa voce dal tuo profilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="secondary">
                        Elimina
                    </Button>
                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirm}
                onClose={handleConfirmnoskillsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>Sicuro?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Procedendo, vedrai offerte di lavoro meno accurate. In
                        ogni caso, i tuoi dati non verranno eliminati.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmnoskillsProceed}
                        color="secondary"
                    >
                        Procedi
                    </Button>
                    <Button
                        onClick={handleConfirmnoskillsClose}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default JobCardEducation
