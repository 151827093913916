import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Profile from '../components/Homepage/Profile'
import Likes from '../components/Homepage/Likes'
import Jump from '../components/Homepage/Jump'
import JumpRecruiter from '../components/Homepage/JumpRecruiter'
import ProfileRecruiter from '../components/Homepage/ProfileRecruiter'
import { authRoles } from '../auth/authRoles'
import MatchPage from '../components/Homepage/MatchPage'
import MatchPageRecruiter from '../components/Homepage/MatchPageRecruiter'
import LikesRecruiter from '../components/Homepage/LikesRecruiter'
import FiltersPage from '../components/Homepage/FilterPage'
import FilterPageRecruiter from '../components/Homepage/FilterPageRecruiter'
import JobCreated from '../components/Homepage/ProfileComponents/JobCreated'
import JobCardEmpty from 'app/components/Homepage/ProfileComponents/JobCardEmpty'
import { exact } from 'prop-types'
import Pdfcandidato from 'app/components/Homepage/CompleteForm/Pdfcandidato'
import PdfDesign from 'app/components/Homepage/CompleteForm/Pdfdesign'

const redirectRoute = [
    {
        path: '/profile',
        component: Profile,
        auth: authRoles.candidate,
    },
    {
        path: '/likes',
        component: Likes,
        auth: authRoles.candidate,
    },
    {
        path: '/likes-recruiter',
        component: LikesRecruiter,
        auth: authRoles.recruiter,
    },
    {
        path: '/jump',
        component: Jump,
        auth: authRoles.candidate,
    },
    {
        path: '/jump-recruiter',
        component: JumpRecruiter,
        auth: authRoles.recruiter,
    },
    {
        path: '/match',
        component: MatchPage,
        auth: authRoles.candidate,
    },
    {
        path: '/match-recruiter',
        component: MatchPageRecruiter,
        auth: authRoles.recruiter,
    },
    {
        path: '/settings',
        component: FiltersPage,
        auth: authRoles.candidate,
    },

    // {
    //     path: '/pdf-candidato',
    //     component: Pdfcandidato,
    //     auth: authRoles.candidate,
    // },
    {
        path: '/settings-recruiter',
        component: FilterPageRecruiter,
        auth: authRoles.recruiter,
    },
    {
        path: '/profile-recruiter',
        component: ProfileRecruiter,
        auth: authRoles.recruiter,
    },
    {
        path: '/offertaTab/:offer_guid',
        component: JobCreated,
        auth: authRoles.recruiter,
    },
    {
        path: '/nouvoOfferta',
        component: JobCardEmpty,
        auth: authRoles.recruiter,
    },
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/profile" />,
    },

    // {
    //     path: '/start',
    //     exact: true,
    //     component: () => <Redirect to="/" />,
    // },
]

const errorRoute = [
    {
        component: () => <Redirect to="/404" />,
    },
]

const routes = [...redirectRoute, ...errorRoute]

export default routes
