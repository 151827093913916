import React, { useState, useMemo, Fragment } from 'react'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Switch,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    CircularProgress,
    FormGroup,
    Button,
} from '@material-ui/core'
import { useEffect } from 'react'
import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import useAuth from 'app/hooks/useAuth'
import ApiService from 'app/ApiService'
import Autocomplete from '../../Autocomplete/AsyncAutocomplete'
import Sectors from '../../Tables/sectors_simplified'
import years_ranges from '../../Tables/years_ranges'
import { makeStyles } from '@material-ui/core/styles'
import { set } from 'lodash'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
const SectionTitles = [
    'Raccontaci di più sulla tua ultima o più ',
    /* "Raccontaci di più sull'azienda dove hai lavorato", */
    '',
]
const useStyle = makeStyles({
    root: {
        '& .MuiSwitch-edgeEnd': {
            background: '#EA6C3A',
            color: '#EA6C3A',
        },
        '& 	.MuiSwitch-switchBase': {
            color: '#EA6C3A',
        },
        '& .Mui-checked': {
            // background: 'violet',
            color: '#EA6C3A',
        },
        '& .MuiSwitch-track': {
            backgroundColor: 'grey',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#EA6C3A',
        },
    },
})

function OnboardingWorkExp({
    stepOnboarding,
    setStepOnboarding,
    skipDetails,
    setSkipDetails,
}) {
    const [stepOnboardingWorkExp, setStepOnboardingWorkExp] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    // const [enterAteco, setEnterAteco] = useState('not-answered')
    const [previousExperience, setPreviousExperience] = useState('not-answered')
    const [appState, setAppState] = useState(false)
    const [years_exp, setYearsExp] = useState('')
    const [showMore, setShowMore] = useState(false)
    // const classes = useStyle()
    // const [active, setActive] = useState(false)
    const initUserInfo = {
        candidate_guid: '',
        job_title: '',
        company_name: '',
        location: '',
        job_lv4: '',
        job_lv3: '',
        job_lv2: '',
        job_lv1: '',
        sector_lv4: [],
        sector_lv3: [],
        sector_lv2: [],
        sector_lv1: [],
        sector: [],
        current_job: false,
        job_date_start: '',
        job_date_end: '',
        job_percentage: 100,
        description: '',
        ateco_description: '',
        company_description: '',
        freelance: false,
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        candidate_guid,
        job_title,
        company_name,
        location,
        job_lv4,
        job_lv3,
        job_lv2,
        job_lv1,
        sector_lv4,
        sector_lv3,
        sector_lv2,
        sector_lv1,
        sector,
        current_job,
        job_date_start,
        job_date_end,
        job_percentage,
        description,
        ateco_description,
        company_description,
        freelance,
        created,
        lastUpdate,
    } = userInfo

    const {
        user,
        // user
    } = useAuth()

    const defaultSectors = [
        'Commercio al dettaglio',
        'Commercio all’ingrosso',
        'Edilizia',
        'Logistica e trasporti',
        'Manufatturiero',
        'Meccanici e carrozzieri',
        'Ristorazione ed alloggio',
    ]

    const additionalSectors = Sectors.filter(
        (category) => !defaultSectors.includes(category)
    )

    const handleToggleShowMore = () => {
        setShowMore(!showMore)
    }
    const getExpList = async () => {
        try {
            const response = await ApiService.get_candexpbyguid(candidate_guid)
            const body = await response.json()
            console.log(body)

            if (body?.length > 0) {
                let expInfo = body[0]
                setUserInfo({
                    ...userInfo,
                    id: expInfo.id,
                    job_title: expInfo.job_title,
                    company_name: expInfo.company_name,
                    location: expInfo.location,
                    sector: JSON.parse(expInfo.sector),
                    current_job: expInfo.current_job,
                    job_date_start: new Date(expInfo.job_date_start),
                    job_date_end: new Date(expInfo.job_date_end),
                    job_percentage: expInfo.job_percentage,
                    description: expInfo.description,
                    lastUpdate: new Date(),
                })

                if (expInfo.current_job) {
                    setSkipDetails({ ...skipDetails, skip_work: false })
                    setPreviousExperience('yes')
                } else {
                    setSkipDetails({ ...skipDetails, skip_work: true })
                    setPreviousExperience('no')
                }

                if (
                    JSON.parse(expInfo.sector).some(
                        (sector) => !defaultSectors.includes(sector)
                    )
                )
                    setShowMore(true)

                const startDate = new Date(expInfo.job_date_start)
                const endDate = new Date(expInfo.job_date_end)

                const monthDifference =
                    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
                    endDate.getMonth() -
                    startDate.getMonth()

                switch (true) {
                    case monthDifference < 12:
                        setYearsExp(
                            years_ranges.filter((x) => x.months < 12)[0].label
                        )
                        break
                    case monthDifference >= 12 && monthDifference < 24:
                        setYearsExp(
                            years_ranges.filter(
                                (x) => x.months >= 12 && x.months < 24
                            )[0].label
                        )
                        break
                    case monthDifference >= 24 && monthDifference < 60:
                        setYearsExp(
                            years_ranges.filter(
                                (x) => x.months >= 24 && x.months < 60
                            )[0].label
                        )
                        break
                    case monthDifference >= 60 && monthDifference < 120:
                        setYearsExp(
                            years_ranges.filter(
                                (x) => x.months >= 12 && x.months < 24
                            )[0].label
                        )
                        break
                    case monthDifference >= 120:
                        setYearsExp(
                            years_ranges.filter((x) => x.months >= 120)[0].label
                        )
                        break
                }
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
    }

    const onChecked = (event) => {
        const categoryName = event.target.name
        const isChecked = event.target.checked
        // console.log(categoryName)

        // console.log(isChecked)
        let array = []
        let prevSelected = sector
        if (isChecked) {
            // If checked, add it to the array
            array = [...prevSelected, categoryName]
        } else {
            // If unchecked, remove it from the array
            array = prevSelected.filter((category) => category !== categoryName)
        }
        // console.log(array)

        setUserInfo({
            ...userInfo,
            sector: array,
        })

        // console.log(userInfo)
    }

    const onChangePrevExp = (value) => {
        setPreviousExperience(value)
        if (value === 'no') {
            setSkipDetails({ ...skipDetails, skip_work: true })
            setYearsExp('')
            setUserInfo({
                ...userInfo,
                candidate_guid: user.uid,
                job_title: job_title,
                current_job: false,
                job_date_start: '',
                job_date_end: '',
                sector: [],
            })
        } else {
            setSkipDetails({ ...skipDetails, skip_work: false })
            setUserInfo({ ...userInfo, current_job: true })
        }
        console.log(userInfo)
    }

    const onSelect = (event) => {
        const months = years_ranges.filter(
            (x) => x.label === event.target.value
        )[0].months
        setYearsExp(event.target.value)
        setUserInfo({
            ...userInfo,
            job_date_start: computeStartDate(months),
        })
    }

    const computeStartDate = (months) => {
        // Assuming event.target.value contains the number of months to subtract
        var monthsToSubtract = parseInt(months, 10)

        // Get the current date
        var currentDate = new Date()

        // Calculate the new month and year
        var newMonth = currentDate.getMonth() - (monthsToSubtract % 12)
        var yearsToSubtract = Math.floor(monthsToSubtract / 12)
        var newYear = currentDate.getFullYear() - yearsToSubtract

        // Adjust the month and year if needed
        while (newMonth < 0) {
            newMonth += 12
            newYear--
        }

        // Set the new month and year
        currentDate.setMonth(newMonth)
        currentDate.setFullYear(newYear)

        // Now, currentDate holds the result
        console.log(currentDate)
        return currentDate
    }

    const validate = () => {
        const errors = {}

        switch (stepOnboardingWorkExp) {
            case 0:
                if (previousExperience === 'not-answered')
                    errors.previous_exp = 'Questo campo non può essere nullo'
                if (previousExperience === 'no' && !job_title)
                    errors.first_exp = 'Questo campo non può essere nullo'
                if (previousExperience === 'yes' && !job_title)
                    errors.job_title = "Inserisci un'esperienza"
                if (previousExperience === 'yes' && years_exp <= 0)
                    errors.years_exp = 'Inserisci un range di esperienza'
                break
            case 1:
                if (sector.length <= 0)
                    errors.sector = 'Scegli almeno un settore'
                break
        }
        return errors
    }

    async function handleNext() {
        if (skipDetails.skip_work) {
            const res = await ApiService.create_candexp(userInfo)
            if (res.ok) {
                setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
            }
        } else {
            if (stepOnboardingWorkExp >= SectionTitles.length - 1) {
                const res = await ApiService.create_candexp(userInfo)
                if (!res.ok)
                    return //aggiungere messaggio di errore o schermata 404
                else await ApiService.create_candhsbyjobTitle(userInfo)
                setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
            }
            setStepOnboardingWorkExp((prevActiveStep) =>
                prevActiveStep !== SectionTitles.length - 1
                    ? prevActiveStep + 1
                    : prevActiveStep
            )
        }
        setSubmit(false)
    }

    async function getData() {
        await getExpList()
        setAppState(false)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            } else setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        if (appState === true) getData()
    }, [appState])

    useEffect(() => {
        if (user !== null) {
            setUserInfo({ ...userInfo, candidate_guid: user?.uid })
            setAppState(true)
        }
    }, [user])

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className="onboarding-grid">
                <div className="text-center">
                    <p className="smallheader">
                        {SectionTitles[stepOnboardingWorkExp]}
                    </p>
                </div>
                {(() => {
                    switch (stepOnboardingWorkExp) {
                        case 0:
                            return (
                                <>
                                    <p className="text-center smallheader">
                                        rilevante esperienza lavorativa
                                    </p>
                                    <div
                                        className="input-container "
                                        style={{
                                            gap: '0.5rem',
                                            marginTop: '-0.7rem',
                                        }}
                                    >
                                        <FormControl
                                            component="fieldset"
                                            className="m1 column"
                                            style={{ marginBottom: '0' }}
                                        >
                                            {/* <FormLabel component="h3">Conosci il o gli ateco della tua azienda?</FormLabel> */}
                                            <p
                                                className="input-error onboarding"
                                                style={{
                                                    marginTop: '1rem',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {formErrors.previous_exp}
                                            </p>
                                            <h3
                                                component="legend"
                                                className="text-center"
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Hai già maturato esperienza
                                                lavorativa?
                                            </h3>
                                            <div className="swichOnBoarding">
                                                <div
                                                    onClick={() =>
                                                        onChangePrevExp('yes')
                                                    }
                                                    style={{
                                                        padding: '10px 20px',
                                                        border: '1px solid #ccc',
                                                        width: '6rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        backgroundColor:
                                                            previousExperience ===
                                                            'yes'
                                                                ? '#ea6c3a'
                                                                : 'transparent',
                                                        color:
                                                            previousExperience ===
                                                            'yes'
                                                                ? 'white'
                                                                : 'black',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Si
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        onChangePrevExp('no')
                                                    }
                                                    style={{
                                                        padding: '10px 20px',
                                                        border: '1px solid #ccc',
                                                        width: '6rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        backgroundColor:
                                                            previousExperience ===
                                                            'no'
                                                                ? '#ea6c3a'
                                                                : 'transparent',
                                                        color:
                                                            previousExperience ===
                                                            'no'
                                                                ? 'white'
                                                                : 'black',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    No
                                                </div>
                                            </div>
                                            {/* SWITCH 
                                            <h4>No&nbsp;&nbsp;Si</h4>
                                            <Switch
                                                color="primary"
                                                size="medium"
                                                // disabled={active}
                                                // onChange={getValue}
                                                checked={active}
                                                onChange={handleSwitchChange}
                                                className={classes.root}
                                            />*/}
                                        </FormControl>

                                        {/*{previousExperience == 'not-answered' &&
                                            null} */}
                                        {previousExperience == 'yes' && (
                                            <>
                                                <div>
                                                    <p
                                                        className="input-error onboarding"
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {formErrors.job_title}
                                                    </p>
                                                    <h4
                                                        component="legend"
                                                        className="text-center"
                                                        style={{
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        {' '}
                                                        In quale ruolo?
                                                    </h4>
                                                    <Autocomplete
                                                        parentValue={job_title}
                                                        onChange={(newValue) =>
                                                            setUserInfo({
                                                                ...userInfo,
                                                                job_title:
                                                                    newValue,
                                                            })
                                                        }
                                                        label={'Digita qui'}
                                                        apiRequest={
                                                            ApiService.get_joblist
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <p
                                                    className="input-error  text-center onboarding"
                                                    style={{
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    {formErrors?.years_exp}
                                                </p>
                                                <FormControl
                                                    component="fieldset"
                                                    className="tags-check"
                                                    style={{
                                                        marginTop: '-0.5rem',
                                                    }}
                                                >
                                                    <h4
                                                        component="legend"
                                                        className="text-center onboarding"
                                                    >
                                                        Anni di esperienza
                                                        totali nel ruolo
                                                    </h4>

                                                    <RadioGroup
                                                        aria-label="Anni di esperienza"
                                                        value={years_exp}
                                                        onChange={onSelect}
                                                        style={{
                                                            marginBottom:
                                                                '0.5rem',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        {years_ranges.map(
                                                            (
                                                                category,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <div className="yearsonboarding1">
                                                                        <FormControlLabel
                                                                            className="yearsonboarding yearsexpTag"
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                category.label
                                                                            }
                                                                            control={
                                                                                <Radio />
                                                                            }
                                                                            label={
                                                                                category.label
                                                                            }
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </>
                                        )}
                                        {previousExperience == 'no' && (
                                            <div>
                                                <h4
                                                    component="legend"
                                                    className="text-center onboarding"
                                                >
                                                    Descrivici che lavoro vuoi
                                                    cercare
                                                </h4>
                                                <div>
                                                    <p
                                                        className="input-error onboarding"
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {formErrors.first_exp}
                                                    </p>
                                                    <Autocomplete
                                                        parentValue={job_title}
                                                        onChange={(newValue) =>
                                                            setUserInfo({
                                                                ...userInfo,
                                                                job_title:
                                                                    newValue,
                                                            })
                                                        }
                                                        label={'Digita qui'}
                                                        apiRequest={
                                                            ApiService.get_joblist
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )
                        /* case 1:
                            return (
                                <>
                                    <div className="input-container">
                                        <div>
                                            <p className='input-error'>{formErrors.company_name}</p>
                                            <TextField
                                                label="Nome azienda"
                                                variant="filled"
                                                name='company_name'
                                                value={company_name}
                                                onChange={onChange}
                                                fullWidth
                                                required
                                                color="primary"
                                            />
                                        </div>

                                        <div>
                                            <p className='input-error'>{formErrors.location}</p>
                                            <TextField
                                                label="In quale città hai svolto il tuo ruolo?"
                                                variant="filled"
                                                name='location'
                                                value={location}
                                                onChange={onChange}
                                                fullWidth
                                                required
                                                color="primary"
                                            />
                                        </div>
                                    </div>
                                </>
                            ) */
                        case 1:
                            return (
                                <div className="onboarding-grid">
                                    <FormControl
                                        component="fieldset"
                                        className="tags-check"
                                    >
                                        <div className="HeaderExp">
                                            <h4
                                                component="legend"
                                                className="text-center  smallheader"
                                            >
                                                In quale o quali tra i seguenti
                                                settori opera l’azienda
                                            </h4>
                                            <h4
                                                component="legend"
                                                className="text-center  smallheader"
                                            >
                                                per cui hai lavorato con il
                                                ruolo indicato prima
                                            </h4>
                                        </div>
                                        <p className="input-error text-center onboarding">
                                            {formErrors.sector}
                                        </p>

                                        <div className="ExpOnboardingTags">
                                            <FormGroup
                                                name="sector"
                                                value={sector}
                                            >
                                                {defaultSectors.map(
                                                    (category, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            label={category}
                                                            name="sector"
                                                            control={
                                                                <Checkbox
                                                                    onChange={
                                                                        onChecked
                                                                    }
                                                                    name={
                                                                        category
                                                                    }
                                                                    checked={sector.includes(
                                                                        category
                                                                    )}
                                                                />
                                                            }
                                                        />
                                                    )
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className="text-center">
                                            <Button
                                                onClick={handleToggleShowMore}
                                                className="underline-text"
                                                style={{
                                                    backgroundColor:
                                                        'transparent',
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    outline: 'none',
                                                    color: '#4b4c55',
                                                    fontWeight: '600',
                                                    textTransform: 'math-auto',
                                                    margin: '1rem 0',
                                                }}
                                            >
                                                Vedi altri settori
                                                {showMore ? (
                                                    <ArrowDropUpIcon />
                                                ) : (
                                                    <ArrowDropDownIcon />
                                                )}
                                            </Button>
                                        </div>

                                        {showMore && (
                                            <div className="ExpOnboardingTags">
                                                <FormGroup
                                                    name="sector"
                                                    value={sector}
                                                >
                                                    {additionalSectors.map(
                                                        (category, index) => (
                                                            <FormControlLabel
                                                                key={index}
                                                                label={category}
                                                                name="sector"
                                                                control={
                                                                    <Checkbox
                                                                        onChange={
                                                                            onChecked
                                                                        }
                                                                        name={
                                                                            category
                                                                        }
                                                                        checked={sector.includes(
                                                                            category
                                                                        )}
                                                                    />
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </FormGroup>
                                            </div>
                                        )}
                                    </FormControl>
                                </div>
                            )
                    }
                })()}
            </div>

            <OnboardingButton
                stepOnboarding={stepOnboarding}
                setStepOnboarding={setStepOnboarding}
                setSubmit={setSubmit}
                submit={isSubmit}
            />
        </form>
    )
}

export default OnboardingWorkExp
