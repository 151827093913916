import { azureCode } from 'config'

export default class ApiService {
    //#region Recruiter
    //#region Recruiter offer
    static async get_offerbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }

        const url = '/api/get_offerbyguid?guid=' + id + '&code=' + azureCode

        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_offersbyguid(recruiter_id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }
        const response = await fetch(
            '/api/get_offersbyguid?guid=' + recruiter_id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async get_activeofferbyguid(recruiter_id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }
        const response = await fetch(
            '/api/get_activeofferbyguid?guid=' +
                recruiter_id +
                '&code=' +
                azureCode,
            requestOptions
        )
        return response
    }

    static async update_offerlastUpdate(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offerlastUpdate?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_lastUpdate(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_lastUpdate?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async create_offer(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_offer?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_offer(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offer?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async delete_offer(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_offer?id=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_activeOffer(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_activeOffer?code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Recruiter
    static async create_recruiter(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_recruiter?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async get_recruiterbyId(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }
        const response = await fetch(
            '/api/get_recruiterbyid?guid=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_recruiter(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_recruiter?code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Hard Skill
    static async get_offershsbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }

        const url = '/api/get_offerhsbyguid?guid=' + id + '&code=' + azureCode

        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_offerhsbyjobTitle(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_offerhsbyjobTitle?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async create_offerhs(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_offerhs?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async create_multipleofferhs(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_multipleofferhs?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_offerhs(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offerhs?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async delete_offerhs(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_offerhs?id=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Languages

    static async get_offersLangbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }

        const url =
            '/api/get_offersLangbyguid?guid=' + id + '&code=' + azureCode

        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_offerLang(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_offerLang?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async create_multipleofferLang(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_multipleofferLang?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_offerLang(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offerLang?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async delete_offerLang(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_offerLang?id=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Certification
    static async get_offerCertbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }

        const url = '/api/get_offerCertbyguid?guid=' + id + '&code=' + azureCode

        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_offerCert(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }

        const response = await fetch(
            '/api/create_offerCert?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_offerCert(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offerCert?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async cancel_offerCert(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/cancel_offerCert?code=' + azureCode + '&id=' + id,
            requestOptions
        )
        return response
    }

    static async delete_alloffercert(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_alloffercert?guid=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Soft Skill
    static async get_offersssbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }

        const url = '/api/get_offerssbyguid?guid=' + id + '&code=' + azureCode

        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_offerss(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_offerss?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async create_multipleofferss(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_multipleofferss?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_offerss(id, message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offerss?code=' + azureCode + 'id=' + id,
            requestOptions
        )
        return response
    }

    static async delete_offerss(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_offerss?id=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Title
    static async get_offerstitlebyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }

        const url =
            '/api/get_offertitlebyguid?guid=' + id + '&code=' + azureCode

        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_offertitle(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_offertitle?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_offertitle(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offertitle?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async delete_offertitle(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_offertitle?id=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async delete_alloffertitles(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_alloffertitles?guid=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Experience
    static async get_offersexpbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }

        const url = '/api/get_offerexpbyguid?guid=' + id + '&code=' + azureCode

        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_offerexp(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/create_offerexp?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_offerexp(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_offerexp?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async delete_offerexp(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_offerexp?id=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async delete_allofferexp(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/delete_allofferexp?guid=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    //#endregion

    //#region Recruiter Settings
    static async get_offerfiltersbyid(recruiter_id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(message)
        }
        const response = await fetch(
            '/api/get_offerfiltersbyid?guid=' +
                recruiter_id +
                '&code=' +
                azureCode,
            requestOptions
        )
        return response
    }

    static async update_offerfiltersbyid(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_offerfiltersbyid?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }
    //#endregion

    //#region Recuiter Candidate Handling
    static async find_candidates(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/find_candidates?guid=' + id + '&code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async update_swipe(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const response = await fetch(
            '/api/update_swipe_offer?code=' + azureCode,
            requestOptions
        )
        return response
    }

    static async get_likesSent(offer_guid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/get_likesSent_offer?guid=' +
                offer_guid +
                '&code=' +
                azureCode,
            requestOptions
        )
        return response
    }

    static async get_likesReceived(offer_guid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/get_likesReceived_offer?guid=' +
                offer_guid +
                '&code=' +
                azureCode,
            requestOptions
        )
        return response
    }

    static async get_matches(offer_guid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const response = await fetch(
            '/api/get_matches_offer?guid=' + offer_guid + '&code=' + azureCode,
            requestOptions
        )
        return response
    }
    //#endregion

    //#endregion

    //#region Candidate

    //#region Candidate Candidate

    static async get_candidatebyId(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candidatebyid?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candidate(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candidate?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candidate(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candidate?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_usergeolocation(uid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_usergeolocation/' + uid + '?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_usergeolocation(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_usergeolocation?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_lastUpdate(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_lastUpdate?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_filtersbyid(uid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url =
            '/api/get_candidatefiltersbyid?guid=' + uid + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_filtersbyid(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candidatefiltersbyid?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#region Candidate Hard Skill
    static async get_candhsbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candhsbyguid?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candhs(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candhs?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_multiplecandhs(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_multiplecandhs?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candhsbyjobTitle(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candhsbyjobTitle?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candhs(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candhs?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async delete_candhs(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/delete_candhs?id=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#region Candidate Languages
    static async get_candLangbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candLangbyguid?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candLang(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candLang?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_multiplecandLang(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_multiple_candLang?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candLang(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candLang?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async delete_candLang(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/delete_candLang?id=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#region Candidate Certificate
    static async get_candCertbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candCertbyguid?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candCert(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candCert?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candCert(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candCert?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async delete_candCert(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/delete_candCert?id=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#region Candidate Soft Skill
    static async get_candssbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candssbyguid?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candss(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candss?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_multiplecandss(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_multiplecandss?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candss(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candss?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async delete_candss(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/delete_candss?id=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#region Candidate Title

    static async get_candtitlebyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candtitlebyguid?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candtitle(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candtitle?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candtitle(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candtitle?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async delete_candtitle(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/delete_candtitle?id=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#region Candidate Exp

    static async get_candexpbyguid(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candexpbyguid?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async create_candexp(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/create_candexp?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candexp(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candexp?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async delete_candexp(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/delete_candexp?id=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#region Candidate Recruiter Handling

    static async find_offers(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/find_offers?guid=' + id + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async update_candswipe(message) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/update_candswipe?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_candlikesSent(guid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candlikesSent?guid=' + guid + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_candlikesReceived(guid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url =
            '/api/get_candlikesReceived?guid=' + guid + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_candmatches(guid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_candmatches?guid=' + guid + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async remove_match(message) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/remove_match?code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion

    //#endregion

    //#region Common

    static async login(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message),
        }
        const url = '/api/login'
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_joblist(param) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_joblist?param=' + param + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_hslist(param) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url = '/api/get_hslist?param=' + param + '&code=' + azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    static async get_titlelist(title_name, title_type) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const url =
            '/api/get_titlelist?title_name=' +
            title_name +
            '&title_type=' +
            title_type +
            '&code=' +
            azureCode
        const response = await fetch(url, requestOptions)
        return response
    }

    //#endregion
}
