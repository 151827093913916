import React, { useEffect, useState } from 'react'
import OnboardingInfoJob from './OnboardingInfoJob'
import OnboardingWorkExpRequired from './OnboardingWorkExpRequired'
import OnboardingSoftSkillsRequired from './OnboardingSoftSkillsRequired'
import OnboardingEducationRequired from './OnboardingEducationRequired'
import OnboardingLangRequired from './OnboardingLangRequired'
import OnboardingInfoJobLast from './OnboardingInfoJobLast'
// import OnboardingHardSkillsRequired from './OnboardingHardSkillsRequired';
import OnboardingCompletedRecruiter from './OnboardingCompletedRecruiter'
import useAuth from 'app/hooks/useAuth'
import ApiService from 'app/ApiService'
import FormTitles from 'app/components/Tables/recruiter_form_titles'

function OnboardingRecruiter() {
    const [stepOnboarding, setStepOnboarding] = useState(0)

    const [width, setWidth] = useState('0%')
    // const [stepOnboardingHardSkills, setStepOnboardingHardSkills] = useState(0);
    const [appState, setAppState] = useState(false)
    const [offerInfo, setOfferInfo] = useState([])
    const [userInfo, setUserInfo] = useState({
        recruiter_guid: '',
        name: '',
        surname: '',
        birth_date: '',
        company_name: '',
        headquarters: '',
        phone: '',
        gender: '',
        email: '',
        size: '',
        description: '',
        sector: [],
        created: new Date(),
        lastUpdate: new Date(),
    })

    const {
        recruiter_guid,
        name,
        surname,
        birth_date,
        company_name,
        headquarters,
        phone,
        gender,
        email,
        size,
        description,
        sector,
        created,
        lastUpdate,
    } = userInfo

    const { user } = useAuth()

    const getUserInfo = async () => {
        try {
            const response = await ApiService.get_recruiterbyId(recruiter_guid)
            const body = await response.json()
            // console.log(body)

            setUserInfo({
                ...userInfo,
                name: body.name,
                surname: body.surname,
                phone: body.phone,
                gender: body.gender,
                birth_date: new Date(body.birth_date),
                company_name: body.company_name,
                headquarters: body.headquarters,
                size: body.size,
                description: body.description,
                sector: JSON.parse(body.sector),
                created: body.created,
                lastUpdate: body.lastUpdate,
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const getOfferInfo = async () => {
        try {
            const response = await ApiService.get_activeofferbyguid(
                recruiter_guid
            )
            const body = await response.json()
            console.log(body)

            setOfferInfo(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    useEffect(() => {
        const stepSize = 1
        setWidth(`${(100 / FormTitles.length) * stepSize * stepOnboarding}%`)
    }, [stepOnboarding])

    async function getData() {
        await getUserInfo()
        await getOfferInfo()
    }

    useEffect(() => {
        if (appState === true) {
            getData()
            setAppState(false)
        }
    }, [appState])

    useEffect(() => {
        if (user !== null) {
            setUserInfo({
                ...userInfo,
                email: user?.email,
                recruiter_guid: user?.uid,
            })
            setAppState(true)
        }
    }, [user])

    return (
        <>
            <div
                className="onboarding-title"
                /* hidden={FormTitles[stepOnboarding] === ''}*/
            >
                <h1>{FormTitles[stepOnboarding]}</h1>
                <div className="progressbar">
                    <div
                        style={{ width: width, transition: 'width 0.5s ease' }}
                    ></div>
                </div>
            </div>

            {(() => {
                switch (stepOnboarding) {
                    case 0:
                        return (
                            <>
                                <OnboardingInfoJob
                                    userInfo={userInfo}
                                    offer={offerInfo}
                                    getOffer={getOfferInfo}
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    loading={appState}
                                />
                            </>
                        )
                    case 1:
                        return (
                            <>
                                <OnboardingWorkExpRequired
                                    offer={offerInfo}
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    loading={appState}
                                />
                            </>
                        )
                    case 2:
                        return (
                            <>
                                <OnboardingEducationRequired
                                    offer={offerInfo}
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    loading={appState}
                                />
                            </>
                        )
                    case 3:
                        return (
                            <>
                                <OnboardingLangRequired
                                    offer={offerInfo}
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    loading={appState}
                                />
                            </>
                        )
                    case 4:
                        return (
                            <>
                                <OnboardingSoftSkillsRequired
                                    offer={offerInfo}
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    loading={appState}
                                />
                            </>
                        )
                    case 5:
                        return (
                            <>
                                <OnboardingInfoJobLast
                                    userInfo={userInfo}
                                    offer={offerInfo}
                                    getOffer={getOfferInfo}
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    loading={appState}
                                />
                            </>
                        )
                    // case 6:
                    //     return (
                    //         <>
                    //             <OnboardingHardSkillsRequired
                    //                 offer={offerInfo}
                    //                 stepOnboarding={stepOnboarding}
                    //                 setStepOnboarding={setStepOnboarding}
                    //                 stepOnboardingHardSkills={stepOnboardingHardSkills}
                    //                 setStepOnboardingHardSkills={setStepOnboardingHardSkills}
                    //                 FormTitles={FormTitles}
                    //             />
                    //         </>
                    //     )
                    case 6:
                        return <OnboardingCompletedRecruiter />
                }
            })()}
        </>
    )
}

export default OnboardingRecruiter
