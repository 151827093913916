const LanguagesTable = {
    moreCommon: [
        'Italiano',
        'Inglese',
        'Spagnolo',
        'Albanese',
        'Rumeno',
        'Arabo',
        'Francese',
    ],
    others: [
        'Afar',
        'Afrikaans',
        'Amarico',
        'Aramaico',
        'Armeno',
        'Assamese',
        'Azero',
        'Basco',
        'Beluci',
        'Bengalese',
        'Berbero',
        'Bhojpuri',
        'Bielorusso',
        'Bodo',
        'Bosniaco',
        'Bretone',
        'Bulgaro',
        'Burmese',
        'Cantonese',
        'Catalano',
        'Cebuano',
        'Ceceno',
        'Ceco',
        'Chewa',
        'Cinese',
        'Chirghiso',
        'Comoriano',
        'Coreano',
        'Creolo',
        'Creolo delle seychelles',
        'Croato',
        'Curdo',
        'Còrso',
        'Dakhini',
        'Danese',
        'Dogri',
        'Dzongkha',
        'Ebraico',
        'Esperanto',
        'Estone',
        'Ewe',
        'Faroese',
        'Filippino',
        'Finlandese',
        'Frisone',
        'Fulani',
        'Galiziano',
        'Gallese',
        'Garhwali',
        'Georgiano',
        'Giapponese',
        'Giavanese',
        'Greco',
        'Guaranì',
        'Gujarati',
        'Hakka',
        'Harjanvi',
        'Hausa',
        'Hawaiano',
        'Hindi',
        'Hiligaynon',
        'Hmong',
        'Hokkien',
        'Igbo',
        'Indonesiano',
        'Irlandese',
        'Islandese',
        'Kannada',
        'Kashmiri',
        'Kazako',
        'Khmer',
        'Kikongo',
        'Kinyarwanda',
        'Kirundi',
        'Kodava',
        'Konkani',
        'Kumaoni',
        'Kutchi',
        'Laotiano',
        'Latino',
        'Lettone',
        'Lingala',
        'Lingua dei segni',
        'Lituano',
        'Luo',
        'Lussemburghese',
        'Macedone',
        'Maithili',
        'Malayalam',
        'Malese',
        'Malgascio',
        'Maltese',
        'Mandarino',
        'Maori',
        'Marathi',
        'Marwari',
        'Maya',
        'Meitei',
        'Mongolo',
        'Montenegrino',
        'Nahuatl',
        'Ndebele',
        'Nepalese',
        'Norvegese',
        'Olandese',
        'Occitano',
        'Oriya',
        'Oromo',
        'Pahari',
        'Papiamento',
        'Pashto',
        'Patois giamaicano',
        'Persiano',
        'Polacco',
        'Portoghese',
        'Punjabi',
        'Quechua',
        'Romancio',
        'Russo',
        'Sami',
        'Sankethi',
        'Sanscrito',
        'Santali',
        'Saurashtra',
        'Sepedi',
        'Serbo',
        'Sesotho',
        'Shona',
        'Sindebele',
        'Sindhi',
        'Singalese',
        'Slovacco',
        'Sloveno',
        'Somalo',
        'Sranan tongo',
        'Sundanese',
        'Svedese',
        'Swahili',
        'Swazi',

        'Tagalog',
        'Taiwanese',
        'Tajiki',
        'Tamil',
        'Tataro',
        'Telugu',
        'Teochew',
        'Tedesco',
        'Thailandese',
        'Tibetano',
        'Tigrino',
        'Tsonga',
        'Tswana',
        'Tulu',
        'Tunisino',
        'Turco',
        'Turkmeno',
        'Twi',
        'Ucraino',
        'Ulguro',
        'Ungherese',
        'Urdu',
        'Usbeco',
        'Valenzano',
        'Venda',
        'Vietnamita',
        'Wolof',
        'Xhosa',
        'Yiddish',
        'Yoruba',
        'Zulù',
    ],
}
export default LanguagesTable
